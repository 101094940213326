<div class="row">
  <div class="col-lg-4 col-xxl-3">
    <app-chat-sidebar
      [listParticipant]="listParticipant"
      (selectParticipantEvent)="selectParticipantEvent($event)"
    ></app-chat-sidebar>
  </div>
  <div class="col-lg-8 col-xxl-9">
    <mat-card
      class="mb-25 chat-box tagus-card"
      [class.component-dark-theme]="themeService.isDark()"
      [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    >
      <mat-card-content>
        <div
          class="chat-header p-15 d-md-flex align-items-center justify-content-space-between"
        >
          <div class="user d-flex align-items-center">
            <img
              src="assets/img/user.png"
              width="50"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-12">
              <h5 class="fs-16 mb-4">{{ selectedParticipant }}</h5>
            </div>
          </div>
        </div>
        <div class="chat-body p-15">
          <ul class="ps-0 list-unstyled mb-0 mt-0">
            <li
              class="position-relative"
              *ngFor="let message of selectedMessages"
              [ngClass]="{ right: message.sender === 'user' }"
            >
              <img
                src="assets/img/icon-bot.png"
                width="50"
                class="rounded-circle user"
                alt="user"
                *ngIf="message.sender === 'bot'"
              />
              <img
                src="assets/img/user.png"
                width="50"
                class="rounded-circle user"
                alt="user"
                *ngIf="message.sender === 'user'"
              />
              <div class="message">
                <p>{{ message.message }}</p>
              </div>
              <span class="time d-block muted-color mt-8 fs-14">
                {{ message.timestamp | date: 'shortTime' }}
              </span>
            </li>
          </ul>
        </div>
        <div class="chat-footer p-15 d-md-flex align-items-center">
          <div class="list">
            <button mat-icon-button>
              <i class="ri-emotion-line"></i>
            </button>
            <button mat-icon-button>
              <i class="ri-link-m"></i>
            </button>
          </div>
          <form class="position-relative">
            <input
              type="text"
              class="input-text"
              placeholder="Escribe algo..."
            />
            <button type="submit"><i class="ri-send-plane-fill"></i></button>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
