import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-edit-messages-table',
  templateUrl: './add-edit-messages-table.component.html',
  styleUrls: ['./add-edit-messages-table.component.scss'],
})
export class AddEditMessagesTableComponent implements OnInit, OnChanges {
  @Input() questionForm: FormGroup; // Mantener como @Input()
  public editingIndex: number | null = null;
  public isMultipleChoice: boolean = false;
  @Input() questions: any[] = [];
  public dataSource = new MatTableDataSource<any>([]);
  public editedQuestion: any = {};
  private originalQuestion: any;
  public typeTranslations: any = {
    'text': 'Texto',
    'multiple-choice': 'Opción Múltiple',
    'informative': 'Informativo',
  };
  @Input() displayedColumns: string[] = [
    'position',
    'question',
    'variable',
    'type',
    'options',
    'actions',
  ];

  @Output() saveData = new EventEmitter<any>();
  @Output() updateData = new EventEmitter<any>();
  @Output() deleteData = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {
    this.questionForm = this.fb.group({
      question: ['', Validators.required],
      type: ['', Validators.required],
      options: [''],
    });
  }

  ngOnInit() {
    this.dataSource.data = this.questions;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = this.questions;
  }

  addQuestion() {
    const questionData = this.questionForm.value;
    if (questionData.type === 'multiple-choice' && questionData.options) {
      questionData.options = questionData.options
        .split(',')
        .map((option: string) => option.trim());
    } else {
      questionData.options = [];
    }
    this.questions.push({ ...questionData });
    this.dataSource.data = this.questions;
    this.questionForm.reset();

    this.saveData.emit(this.questions);
  }

  onTypeChange(event: any) {
    this.isMultipleChoice = event.value === 'multiple-choice';
  }

  deleteQuestion(question: any) {
    this.questions = this.questions.filter((q) => q !== question);
    this.dataSource.data = this.questions;
    this.deleteData.emit(this.questions);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    this.dataSource.data = this.questions;
    this.updateData.emit(this.questions);
  }

  saveQuestion(index: number) {
    const editedOptions = this.editedQuestion.options;
    if (typeof editedOptions === 'string') {
      this.editedQuestion.options = editedOptions
        .split(',')
        .map((option: string) => option.trim());
    }
    this.questions[index] = { ...this.editedQuestion };
    this.dataSource.data = this.questions;

    // Restablecer variables de edición sin sobrescribir la pregunta actualizada
    this.editingIndex = null;
    this.editedQuestion = {};
    this.originalQuestion = null;

    this.updateData.emit(this.questions);
  }

  editQuestion(question: any, index: number) {
    this.editingIndex = index;
    this.editedQuestion = { ...question };
    this.originalQuestion = { ...question };

    // Convertir opciones a cadena si es opción múltiple
    if (
      this.editedQuestion.type === 'multiple-choice' &&
      Array.isArray(this.editedQuestion.options)
    ) {
      this.editedQuestion.options = this.editedQuestion.options.join(', ');
    }
  }

  cancelEdit() {
    if (this.editingIndex !== null && this.originalQuestion) {
      // Restaurar la pregunta original solo si se cancela la edición
      this.questions[this.editingIndex] = { ...this.originalQuestion };
      this.dataSource.data = this.questions;
    }
    this.editingIndex = null;
    this.editedQuestion = {};
    this.originalQuestion = null;
  }

  private showSwal(
    icon: 'success' | 'error' | 'warning' | 'info',
    title: string,
    text: string
  ) {
    Swal.fire({
      icon: icon,
      text: text,
      iconHtml:
        icon === 'success'
          ? '<i class="ri-check-line" style="color: green;"></i>'
          : undefined,
      showConfirmButton: false,
      timer: 3000,
      backdrop: false,
      toast: true,
      position: 'top-end',
      background: '#ffffff',
      color: '#1d2c4d',
      padding: '1rem',
      width: '25rem',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }
}
