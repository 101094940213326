import { ProgramsFilter } from './../../interfaces/Programs.interface';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, shareReplay, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Programs } from '@core/interfaces/Programs.interface';
import { CoreService } from '@core/core.service';
import { AuthStateService } from '@core/states/auth-state.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  public urlBackEnd: string = environment.base_url;
  public userData: any;
  public headers: any;
  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }
  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getListPrograms(page = 1, limit = 10): Observable<Programs> {
    const headers = this.getHeaders();
    const params = new HttpParams()
      .set('limit', page.toString())
      .set('offset', limit.toString());
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs`, {
        headers: headers,
      })
      .pipe(
        retry(1),
        shareReplay(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene una lista paginada de programas.
   * @param {number} page - Número de página.
   * @param {number} limit - Límite de programas por página.
   * @returns {Observable<Programs>} - Observable que emite un objeto con la lista paginada de programas.
   */
  getListProgramsPaginate(
    page: number,
    limit: number,
    filters?: ProgramsFilter
  ): Observable<Programs> {
    const headers = this.getHeaders();
    //Establecer body de la solicitud
    let body: ProgramsFilter = {};
    //Configurar paramentros de consulta
    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    if (filters) {
      if (Object.keys(filters).length > 0) {
        body = { ...filters };
      }
    }

    return this.http
      .post<Programs>(`${this.urlBackEnd}/programs/paginate`, body, {
        params: params,
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  getProgramById(id: string): Observable<Programs> {
    const headers = this.getHeaders();
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/${id}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getProgramById: ${error.status},` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  getListTypePrograms(): Observable<Programs> {
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/`, { headers: this.headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  getListFilterTypePrograms(): Observable<Programs> {
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/FilterType`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListFilterTypePrograms: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene los filtros disponibles para la búsqueda de programas.
   * @returns {Observable<Programs>} - Observable que emite un objeto con los filtros disponibles.
   */
  getListFiltersPrograms(): Observable<any> {
    return this.http
      .get<Programs>(`${this.urlBackEnd}/programs/filters`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de los participantes';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  registerNewProgram(data: Programs): Observable<Programs> {
    const headers = this.getHeaders();
    return this.http
      .post<Programs>(`${this.urlBackEnd}/programs`, data, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al registrar el programa';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de registerNewProgram: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  updateProgram(id: string, data: Programs): Observable<Programs> {
    const headers = this.getHeaders();
    return this.http
      .patch<Programs>(`${this.urlBackEnd}/programs/${id}`, data, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al actualizar el programa';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de updateProgram: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }
}
