import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlEnum } from '../enum/url.enum';
import { AuthStateService } from '@core/states/auth-state.service';
import { Multimedias } from '@core/interfaces/multimedia.interface';

@Injectable({
  providedIn: 'root',
})
export class MultimediasService {
  public urlBackend: string = environment.base_url;
  public headers: any;
  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';

  public urlMultimedia: string = UrlEnum.URL_MULTIMEDIA;
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('user', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  private getHeadersFormData(): HttpHeaders {
    return new HttpHeaders()
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('user', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getMediaById(id: string): Observable<Multimedias> {
    return this.http
      .get<Multimedias>(`${this.urlBackend}/${this.urlMultimedia}/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  getListMedia(
    limit: number,
    offset: number,
    searchValue: string = ''
  ): Observable<Multimedias> {
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString());

    if (searchValue) {
      params = params.set('searchValue', searchValue);
    }
    return this.http
      .get<Multimedias>(`${this.urlBackend}/${this.urlMultimedia}`, {
        headers: this.getHeaders(),
        params: params, // Pasar los parámetros de paginación en la solicitud GET
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las líneas';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getListMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(errorMessage);
        })
      );
  }

  sendPost(dtForm: any): Observable<Multimedias> {
    if (this.isFormDataEmpty(dtForm)) {
      console.log('El FormData está vacío');
    } else {
      console.log('El FormData no está vacío');
    }
    return this.http
      .post<Multimedias>(`${this.urlBackend}/${this.urlMultimedia}`, dtForm, {
        headers: this.getHeaders(),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las medias';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de sendPost: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  isFormDataEmpty(formData: FormData): boolean {
    const fields = formData.getAll('');

    // Verifica si la longitud de los campos es cero
    return fields.length === 0;
  }

  cargarMedia(media: any): Observable<Multimedias> {
    return this.http
      .post<Multimedias>(`${this.urlBackend}/${this.urlMultimedia}`, media, {
        headers: this.getHeadersFormData(),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las medias';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de cargarMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  guardarLinkVideo(data: any): Observable<any> {
    return this.http
      .post(`${this.urlBackend}/${this.urlMultimedia}`, data, {
        headers: this.getHeaders(),
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las medias';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de cargarMedia: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
