import { map, Observable, startWith } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

// Interface
import { Programs, Step } from '@core/interfaces/Programs.interface';
import {
  SubCategories,
  subCategory,
} from '@core/interfaces/subCategoriesProgram.interface';
// Services
import { SweetAlertService } from '@core/services/sweet-alert.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { SubCategoriesProgramsService } from '@core/services/configurations/sub-categories-programs.service';
// components
import { DialogMediaComponent } from '@modules/programs/components/dialog-media/dialog-media.component';

@Component({
  selector: 'app-create-edit-programs',
  templateUrl: './create-edit-programs.component.html',
  styleUrls: ['./create-edit-programs.component.scss'],
})
export class CreateEditProgramsComponent implements OnInit {
  public listCategory: subCategory[] = [];
  public listTypePrograms: any[] = [];
  public listSteps: Step[] = [];
  public newSteps: Step[] = [];
  public isAddingNewCategory: boolean = false;
  public subcategoryEl: any = '';

  public idProgram: string = '';

  typeForm = new FormControl('');

  filteredOptions: Observable<any[]> | undefined;

  public listCoverage = [
    {
      name: 'Sprint 1',
    },
    {
      name: 'Sprint 2',
    },
    {
      name: 'Sprint 3',
    },
    {
      name: 'Sprint 4',
    },
    {
      name: 'Sprint 5',
    },
    {
      name: 'Sprint 6',
    },
    {
      name: 'Sprint 7',
    },
    {
      name: 'Sprint 8',
    },
    {
      name: 'Sprint 9',
    },
    {
      name: 'Sprint 10',
    },
    {
      name: 'Sprint 11',
    },
    {
      name: 'Sprint 12',
    },
    {
      name: 'Sprint 13',
    },
    {
      name: 'Sprint 14',
    },
    {
      name: 'Sprint 15',
    },
    {
      name: 'Sprint 16',
    },
    {
      name: 'Sprint 17',
    },
    {
      name: 'Sprint 18',
    },
    {
      name: 'Sprint 19',
    },
    {
      name: 'Sprint 20',
    },
  ];

  public programs!: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: Router,
    private router: ActivatedRoute,
    private sweetAlertService: SweetAlertService,
    public _programsService: ProgramsService,

    public _subCateogryService: SubCategoriesProgramsService
  ) {}

  ngOnInit(): void {
    this.idProgram = this.router.snapshot.queryParamMap.get('id') || '';
    if (this.idProgram) {
      this.getProgramById(this.idProgram);
    }

    this.getSubCategories();
    // this.getListTypePrograms();

    this.filteredOptions = this.typeForm.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.programs = this.fb.group({
      program_name: ['', Validators.required],
      program_type: [''],
      menu_option: ['', Validators.required],
      coverage: [''],
      category: [''],
      startDate: [''],
      endDate: [''],
      steps: [[]],
    });
  }

  getSubCategories() {
    this._subCateogryService.getListSubCategory().subscribe(
      (resp: SubCategories) => {
        if (Array.isArray(resp.data)) {
          this.listCategory = resp.data;
        }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getListTypePrograms() {
    this._programsService.getListFilterTypePrograms().subscribe(
      (resp: any) => {
        this.listTypePrograms = resp.results;
      },
      (err) => {
        console.error(err);
      }
    );
  }
  /**
   *  This function gets the program by id and displays a success or error message.
   * @param id, string - The id of the program.
   */

  getProgramById(id: string) {
    this._programsService.getProgramById(id).subscribe(
      (res: Programs) => {
        if (!Array.isArray(res.data)) {
          this.programs.patchValue({
            program_name: res.data.program_name,
            program_type: res.data.type,
            menu_option: res.data.menu_option,
            coverage: res.data.coverage,
            category: res.data.category,
            endDate: res.data.endDate,
            startDate: res.data.startDate,
          });
          this.subcategoryEl = res.data.category;
          this.listSteps = res.data.steps || [];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  postSubCategory(name: string) {
    this._subCateogryService.postSubCategory(name).subscribe(
      (resp: SubCategories) => {
        if (resp.ok) {
          this.sweetAlertService.showConfirmAlert(
            '¡Subcategoría creada!',
            'Se ha creado la subcategoría correctamente'
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openAddEventDialogMedia(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    step?: Step
  ): void {
    const dialogRef = this.dialog.open(DialogMediaComponent, {
      width: '60vw',
      height: '90vh',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        step,
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        let newStep = {
          numberStep: (this.listSteps.length + 1).toString(),
          interaction: data.interaction,
          description: data.description,
          multimedia: data.media,
          idProgram: '',
        };
        this.listSteps.push(newStep);
        this.programs.patchValue({
          steps: this.listSteps,
        });
      }
    });
  }

  programEvent() {
    this.programs.value.program_type = this.typeForm.value;

    if (!this.idProgram) {
      this._programsService.registerNewProgram(this.programs.value).subscribe(
        (resp: Programs) => {
          if (resp.ok) {
            if (!Array.isArray(resp.data)) {
              this.idProgram = resp.data._id || '';
            }

            this.sweetAlertService.showConfirmAlert(
              '¡Programa creado!',
              'Se ha creado el programa correctamente'
            );

            this.route.navigate(['programs/program_list']);
          }
        },
        (err) => {
          this.sweetAlertService.showErrorAlert(
            '¡Error!',
            'Ha ocurrido un error al crear el programa'
          );
        }
      );
    } else {
      this.sweetAlertService
        .showConfirmActionAlert(
          '✏️ ¿Confirmas que deseas editar este contenido? 📝',
          '¡Atención! ⚠️ Estás a punto de realizar una edición al programa',
          '✅ ¡El Programa ha sido editado con éxito!',
          'Editar'
        )
        .then((result) => {
          if (result.isConfirmed) {
            this._programsService
              .updateProgram(this.idProgram, this.programs.value)
              .subscribe(
                (res: Programs) => {
                  if (res.ok) {
                    this.sweetAlertService.showConfirmAlert(
                      '¡Subcategoría creada!',
                      'Se ha creado la subcategoría correctamente'
                    );

                    this.route.navigate(['programs/program_list']);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        });
    }
  }

  toggleCategoryInput() {
    this.isAddingNewCategory = !this.isAddingNewCategory;
    if (this.isAddingNewCategory) {
      this.programs.get('Subcategory')?.setValue('');
    } else {
      if (this.idProgram) {
        this.programs.patchValue({
          Subcategory: this.subcategoryEl,
        });
      }
      this.programs.get('newCategory')?.setValue('');
    }
  }
  deleteStep(step: Step) {
    this.sweetAlertService
      .showConfirmActionAlert(
        '🤔  ¿Estás seguro? 🗑️',
        '¡Atención! ⚠️ Vas a eliminar un paso',
        '✅ ¡Paso eliminado con éxito!',
        'Eliminar'
      )
      .then((result) => {
        let newList = this.listSteps.filter((item) => item !== step);
        this.listSteps = newList;
        this.programs.patchValue({
          steps: this.listSteps,
        });
        this.programEvent();
      });
  }

  private _filter(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.listTypePrograms.filter(
      (option) => option.type && option.type.toLowerCase().includes(filterValue)
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listSteps, event.previousIndex, event.currentIndex);

    let newPosition: any[] = [];

    for (let index = 0; index < this.listSteps.length; index++) {
      const element = this.listSteps[index];

      let dataJson;

      dataJson = {
        description: element.description,
        interaction: element.interaction,
        media: element.multimedia,
        numberStep: index + 1,
      };

      newPosition.push(dataJson);
    }

    this.listSteps = newPosition;
    this.programs.patchValue({
      steps: this.listSteps,
    });
  }
}
