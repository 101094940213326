import { Injectable } from '@angular/core';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { Observable, BehaviorSubject, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  private filterSubject = new Subject<any>();
  private filterConfigSubject = new Subject<any>();
  private filterData: any = {}; // Propiedad para almacenar los datos de los filtros

  public filter$ = this.filterSubject.asObservable().pipe(
    catchError((error) => {
      console.error('Error filtering data:', error);
      return throwError(error); // Re-throw the error for proper handling
    })
  );

  public filterConfig$ = this.filterConfigSubject.asObservable().pipe(
    catchError((error) => {
      console.error('Error getting filter configs:', error);
      return throwError(error); // Re-throw the error for proper handling
    })
  );

  constructor() {}

  public setFilters(filter: any): void {
    this.filterSubject.next(filter);
  }

  public getFilters(): Observable<any> {
    return this.filter$;
  }

  public getFilterDataByCategory(category: string) {
    return this.filterData[category] || null;
  }

  public setFilterConfigs(configs: any, response?: any): void {
    this.filterData = {};
    // Almacenar los datos de filtro de manera general
    let subCategory: string[] = [];

    // Verificar que 'response' es un objeto válido
    if (!response || typeof response !== 'object') {
      this.filterConfigSubject.next(configs);
      return;
    }

    for (let category of Object.keys(response)) {
      subCategory.push(category);
      let categoryFilters = { ...response[category] };

      // Verificar si 'Subcategory' existe y es un arreglo
      if (Array.isArray(categoryFilters.Subcategory)) {
        categoryFilters.Subcategory = categoryFilters.Subcategory.map(
          (sub: any) => {
            // Si el elemento es un objeto y tiene 'name', devolver 'name'
            if (typeof sub === 'object' && sub !== null && 'name' in sub) {
              return sub.name;
            }
            // Si ya es una cadena, devolverla tal cual
            return sub;
          }
        );
      }

      // Asignar los filtros transformados al objeto 'filterData'
      this.filterData[category] = categoryFilters;
    }

    // Emitir los configs actualizados
    this.filterConfigSubject.next(configs);
  }

  public getFilterConfigs(): Observable<FilterConfig[]> {
    return this.filterConfig$;
  }
}
