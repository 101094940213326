import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  Program,
  Programs,
  ProgramsFilter,
} from '@core/interfaces/Programs.interface';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { FilterService } from '@core/services/filters/filter.service';
import { MatchService } from '@core/services/programs/match.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dialog-add-level',
  templateUrl: './dialog-add-level.component.html',
  styleUrls: ['./dialog-add-level.component.scss'],
})
export class DialogAddLevelComponent implements OnInit, OnDestroy {
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  public loadData = new EventEmitter<any>();
  /** Paginator de la tabla. */
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  displayedColumns: string[] = [
    'program_name',
    'program_type',
    'category',
    'menu_option',
    'state',
    'action',
  ];
  public listPrograms: Program[] = [];
  public listProgramsToSave: Programs[] = [];
  /** Número total de elementos. */
  public totalItems?: number;
  public pageSize: number = 10;
  public pageIndex = 1;
  /*  La configuración de botones de filtrado. */
  filterConfigs: FilterConfig[] = [];

  /** Subscripción a la elección del filtrado */
  private filterSubscription: Subscription | undefined;
  /** Respuesta del servicio con los filtros escogidos*/
  public dataFilters: ProgramsFilter = {};
  public multiChoise: boolean = true;
  public match: any;
  public level: number = 0;
  public levels: any = [];
  public position: string = '';
  public program: any;

  public dataFilterPosition: any;
  public typeEvent = '';
  public multiChoice: boolean = true;

  myControl = new FormControl<string | any>('');
  filteredOptions!: Observable<any[]>;
  dataSource = new MatTableDataSource<Program>(this.listPrograms);
  public filters: any;

  constructor(
    private programsService: ProgramsService,
    private matchService: MatchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddLevelComponent>,
    private filterService: FilterService
  ) {
    if (data) {
      this.match = data.Match;
      this.level = data.level;
      this.levels = data.levels;
      this.position = data.position;
      this.typeEvent = data.isUpdate;
      if (this.typeEvent !== 'returnsId') {
        this.searchLevelByPosition();
      }
    }
  }

  ngOnInit(): void {
    // Obtener configuración de filtros al iniciar
    this.programsService.getListFiltersPrograms().subscribe((resp: any) => {
      const types: string[] = Object.keys(resp.filters);

      // Configuración de los filtros
      this.filterConfigs = [
        {
          name: 'Tipo',
          icon: 'ri-list-check-2',
          options: types,
          dbName: 'program_type',
        },
        {
          name: 'Categoria',
          icon: 'ri-bar-chart-horizontal-fill',
          options: [],
          dbName: 'category',
        },
        {
          name: 'Estado',
          icon: 'ri-bard-line',
          options: [],
          dbName: 'state',
          pipe: 'state',
        },
      ];
      this.filters = resp.filters;
      // Pasar la configuración de filtros al servicio
      this.filterService.setFilterConfigs(this.filterConfigs, resp.filters);
    });

    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe(
      (filters) => {
        if (!filters.program_type) {
          this.dataFilters = {};
          this.filterService.setFilterConfigs(this.filterConfigs, this.filters);
          this.getListProgramsPaginate();
        }
        const selectedCategoryData = this.filterService.getFilterDataByCategory(
          filters.program_type
        );
        if (filters.program_type) {
          if (selectedCategoryData) {
            this.filterConfigs.find(
              (config) => config.name === 'Categoria'
            )!.options = selectedCategoryData.Subcategory ?? '';
            this.filterConfigs.find(
              (config) => config.name === 'Estado'
            )!.options = selectedCategoryData.state ?? '';
          }
        }

        for (const itemFilter in filters) {
          if (itemFilter !== 'searchValue') {
            if (selectedCategoryData[itemFilter] || itemFilter === 'category') {
              const value = selectedCategoryData[itemFilter]?.find(
                (value: any) => value === filters[itemFilter]
              );
              if (itemFilter === 'category') {
                filters[itemFilter] = this.filters[
                  filters.program_type
                ].Subcategory?.find(
                  (category: any) => category.name === filters.category
                )?.id;
                if (!filters[itemFilter]) {
                  delete filters[itemFilter];
                }
              } else if (value) {
                filters[itemFilter] = value;
              } else {
                delete filters[itemFilter];
              }
            }
          }
        }
        // Obtener la lista de programas paginada con los filtros
        this.getListProgramsPaginate(filters);
        this.dataFilters = filters;
      }
    );

    // Obtener la lista inicial de programas paginada
    this.getListProgramsPaginate();
  }

  /** Obtiene la lista de programas con paginación. */
  getListProgramsPaginate(filters?: any) {
    this.programsService
      .getListProgramsPaginate(this.pageIndex, this.pageSize, filters)
      .subscribe(
        (resp: any) => {
          if (resp.ok) {
            this.listPrograms = resp.results.data;
            this.dataSource.data = this.listPrograms;
            this.totalItems = resp.results.pagination.total;
            this.dataSource = new MatTableDataSource(this.listPrograms);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  ngOnDestroy(): void {
    /* Se destruye la subscripción al servicio de filtrado */
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  /** Maneja la selección de programas */
  toggleProgramSelection(program: any): void {
    this.program = program;
    this.typeEventService()
      .then((res) => {
        this.close(res);
      })
      .catch((err) => {
        console.error('Error during typeEventService execution:', err);
      });
  }

  viewCheckedPrograms(programs: any) {
    this.listProgramsToSave = [...programs];
  }

  /** Guarda múltiples programas */
  async saveMultiplePrograms() {
    const listUpdate = [];

    for (let i = 0; i < this.listProgramsToSave.length; i++) {
      if (i >= 1) {
        this.match.position = (parseInt(this.match.position) + 1).toString();
      }
      this.program = this.listProgramsToSave[i];

      try {
        let matchResult;

        switch (this.typeEvent) {
          case 'update':
            matchResult = await this.updateLevel();
            listUpdate.push(matchResult);
            break;

          case 'notUpdate':
            matchResult = await this.registerNewLevel();
            listUpdate.push(matchResult);
            break;

          case 'returnsId':
            await this.registerNewFirstLevel();
            break;

          default:
            console.log('Tipo de evento no reconocido');
        }
      } catch (error) {
        console.error('Error en saveMultiplePrograms:', error);
      }
    }

    this.close(listUpdate);
  }

  async typeEventService(): Promise<any> {
    switch (this.typeEvent) {
      case 'update':
        const matchUpdate = await this.updateLevel();
        return matchUpdate;
      case 'notUpdate':
        const match = await this.registerNewLevel();
        return match;
      case 'returnsId':
        await this.registerNewFirstLevel();
        break;
    }
  }

  async registerNewLevel(): Promise<any> {
    let childrenLength = 1;

    if (this.dataFilterPosition.children) {
      childrenLength = this.dataFilterPosition.children.length + 1;
    } else {
      this.dataFilterPosition.children = [];
    }

    this.dataFilterPosition.children.push({});

    const dataRegister = {
      position: this.dataFilterPosition.position,
      data: {
        level: this.dataFilterPosition.level + 1,
        position: `${this.dataFilterPosition.position}.${childrenLength}`,
        program: this.program._id,
      },
    };

    try {
      const res: any = await firstValueFrom(
        this.matchService.addLevelMatchById(this.match._id, dataRegister)
      );

      if (res.ok) {
        this.showSuccessAlert('Se ha registrado el nuevo Nivel');
        this.dataFilterPosition = { ...this.dataFilterPosition, ...res.data };
        return { method: 'update', response: res.data };
      } else {
        throw new Error('Registration failed');
      }
    } catch (err) {
      console.error(err);
      this.showErrorAlert('Se ha presentado un error al registrar el nivel');
      throw err;
    }
  }

  async updateLevel(): Promise<any> {
    const dataJson = {
      level: this.level,
      position: this.position,
      data: {
        program: this.program._id,
      },
    };

    try {
      const res: any = await firstValueFrom(
        this.matchService.updateMatchProgramById(this.match._id, dataJson)
      );

      if (res.ok) {
        this.showSuccessAlert('Se ha actualizado el programa');
        return { method: 'update', response: res.data };
      } else {
        throw new Error('Update failed');
      }
    } catch (err) {
      console.error(err);
      this.showErrorAlert('Se ha presentado un error al actualizar el nivel');
      throw err;
    }
  }
  // Funciones auxiliares para mostrar alertas
  private showSuccessAlert(message: string) {
    Swal.fire({
      icon: 'success',
      title: message,
      iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
      customClass: {
        popup: 'swal-custom-popup',
        title: 'swal-custom-title',
        htmlContainer: 'swal-custom-content',
      },
      showConfirmButton: false,
      timer: 3000,
      backdrop: false,
      toast: true,
      position: 'top-end',
      background: '#ffffff',
      color: '#1d2c4d',
      padding: '1rem',
      width: '20rem',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }

  private showErrorAlert(message: string) {
    Swal.fire(message, '', 'error');
  }

  searchLevelByPosition() {
    let data = {
      targetPosition: this.position,
      targetLevel: this.level,
      levels: this.match?.levels,
    };

    this.matchService.searchNodeParents(data).subscribe(
      (res: any) => {
        if (res.ok) {
          this.levels;
          this.dataFilterPosition = res.node;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  async registerNewFirstLevel(): Promise<void> {
    const dataRegister = {
      position: this.match.position,
      data: {
        level: 1,
        position: this.match.position,
        program: this.program._id,
      },
    };

    try {
      const res: any = await firstValueFrom(
        this.matchService.addLevelMatchById(this.match._id, dataRegister)
      );

      if (res.ok) {
        this.showSuccessAlert('Se ha registrado el nuevo Nivel');
      } else {
        throw new Error('First level registration failed');
      }
    } catch (err) {
      console.error(err);
      this.showErrorAlert('Se ha presentado un error al registrar el nivel');
      throw err;
    }
  }

  close(res?: any): void {
    this.dialogRef.close(res);
  }

  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    this.getListProgramsPaginate(this.dataFilters);
  }
}
