import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { CommonModule } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerInterceptor } from '@core/interceptors/spinner.interceptor';
import { ParticipantsRoutingModule } from './participants-routing.module';
import { ListParticipantsComponent } from './pages/list-participants/list-participants.component';

/*
import { DialogMediaComponent } from './components/dialog-media/dialog-media.component'; */
import { ComponentsModule } from 'src/app/components/components.module';
import { PrimeNgModule } from 'src/app/primeng.module';

@NgModule({
  declarations: [ListParticipantsComponent],
  imports: [
    CommonModule,
    ParticipantsRoutingModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    CoreModule,
    NgxDropzoneModule,
    PrimeNgModule,
    ComponentsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
})
export class ParticipantsModule {}
