import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile, Profiles } from '@core/interfaces/Profiles.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  public urlBackend: string = environment.base_url;
  public headers: any;

  public UrlModules = 'profile-permissions';

  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';

  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getListProfiles(): Observable<Profiles> {
    const headers = this.getHeaders();

    return this.http
      .get<Profiles>(`${this.urlBackend}/${this.UrlModules}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListProfiles: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  createProfile(dataProfile: Profile): Observable<Profiles> {
    const headers = this.getHeaders();

    return this.http
      .post<Profiles>(`${this.urlBackend}/${this.UrlModules}`, dataProfile, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de createProfile: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  updateProfile(id: string, dataProfile: Profile): Observable<Profiles> {
    const headers = this.getHeaders();

    return this.http
      .put<Profiles>(
        `${this.urlBackend}/${this.UrlModules}/${id}`,
        dataProfile,
        {
          headers: headers,
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de updateProfile: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  changeStateProfile(id: string): Observable<Profile> {
    const headers = this.getHeaders();

    return this.http
      .get<Profile>(
        `${this.urlBackend}/configurations/profiles/changeState/${id}`,
        {
          headers: headers,
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de changeStatusUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  deleteProfile(id: string): Observable<Profiles> {
    const headers = this.getHeaders();

    return this.http
      .delete<Profiles>(`${this.urlBackend}/${this.UrlModules}/${id}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de deleteProfile: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
