import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModulesRoutingModule } from './modules-routing.module';
import { ModulesComponent } from './modules.component';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { LinesGroupsModule } from './lines-groups/lines-groups.module';
import { ProgramsModule } from './programs/programs.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SpinnerInterceptor } from '@core/interceptors/spinner.interceptor';
import { ConfigurationsModule } from './configurations/configurations.module';
import { ParticipantsModule } from './participants/participants.module';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
  declarations: [ModulesComponent],
  imports: [
    CommonModule,
    ModulesRoutingModule,
    MaterialModule,
    SharedModule,
    LinesGroupsModule,
    ProgramsModule,
    ConfigurationsModule,
    ParticipantsModule,
    DashboardModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
})
export class ModulesModule {}
