import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Module, Modules } from '@core/interfaces/modules.interface';
import { Profile, Profiles } from '@core/interfaces/Profiles.interface';
import { ModulesService } from '@core/services/configurations/modules.service';
import { ProfilesService } from '@core/services/configurations/profiles.service';
import { DialogCreateProfilesComponent } from '../dialog/dialog-create-profiles/dialog-create-profiles.component';
import { DialogListUserProfilesComponent } from '../dialog/dialog-list-user-profiles/dialog-list-user-profiles.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modules',
  templateUrl: './modulesProfiles.component.html',
  styleUrls: ['./modulesProfiles.component.scss'],
})
export class ModulesProfilesComponent implements OnInit {
  public listModules: Module[] = [];
  public listProfiles: Profile[] = [];

  public Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  constructor(
    public dialog: MatDialog,
    private _modulesService: ModulesService,
    private _profilesService: ProfilesService
  ) {}

  ngOnInit(): void {
    this.getListModules();
    this.getListProfiles();
  }

  getListModules(): void {
    this._modulesService.getListModules().subscribe(
      (resp: Modules) => {
        if (resp.ok) {
          if (resp.data instanceof Array) {
            this.listModules = resp.data;
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getListProfiles(): void {
    this._profilesService.getListProfiles().subscribe(
      (resp: Profiles) => {
        if (resp.ok) {
          if (resp.data instanceof Array) {
            this.listProfiles = resp.data;
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  changeStatus(profile: any) {
    this._profilesService.changeStateProfile(profile.id).subscribe({
      next: (resp) => {
        Swal.fire({
          title: 'Estado del perfil actualizado',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        if (resp.profile!.state) {
          profile.state = resp.profile!.state;
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  deleteProfile(profile: any, index: number) {
    Swal.fire({
      title: 'Estas Seguro?',
      text: 'Estas seguro de eliminar este perfil?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: ' Si, eliminar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this._profilesService.deleteProfile(profile.id).subscribe(
          (resp) => {
            this.Toast.fire({
              icon: 'success',
              title: 'Se ha eliminado el perfil correctamente',
            });
            this.listProfiles.splice(index, 1);
          },
          (err) => {
            this.Toast.fire({
              icon: 'error',
              title: 'Hubo un error al eliminar perfil',
            });
          }
        );
      }
    });
  }

  openEditProfileDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    profile: Profile
  ): void {
    const dialogRef = this.dialog.open(DialogCreateProfilesComponent, {
      width: '600px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: profile,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getListProfiles();
    });
  }

  openAddEventDialog(
    enterAnimationDuration: string,
    exitAnimationDuration: string
  ): void {
    const dialogRef = this.dialog.open(DialogCreateProfilesComponent, {
      width: '60vw',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getListProfiles();
    });
  }

  openListUsersByProfile(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    profile?: Profile
  ): void {
    const dialogRef = this.dialog.open(DialogListUserProfilesComponent, {
      width: '60vw',
      enterAnimationDuration,
      exitAnimationDuration,
      data: profile,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getListProfiles();
    });
  }
}
