import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Multimedias } from '@core/interfaces/multimedia.interface';

import { Project } from '@core/interfaces/project.interface';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { MultimediasService } from '@core/services/multimedias.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';

@Component({
  selector: 'app-project-modal',
  templateUrl: './project-modal.component.html',
  styleUrls: ['./project-modal.component.scss'],
})
export class ProjectModalComponent {
  private _id: string = '';
  public files: File[] = [];
  public archivos: File | null = null;
  public urlImg = '';
  public projectForm = this.fb.group({
    img: [''],
    name: ['', Validators.required],
    description: [''],
  });

  constructor(
    public fb: FormBuilder,
    public _projectService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProjectModalComponent>,
    private sweetAlertService: SweetAlertService,
    private _mediasService: MultimediasService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      const project = this.data.project;
      this.projectForm.get('name')?.setValue(project.name);
      this.projectForm.get('description')?.setValue(project.description);
      this._id = project._id;
      this.urlImg = project.logo;
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSelect(event: any) {
    if (event.addedFiles.length > 0) {
      const file = event.addedFiles[0];
      this.files.push(file);
      this.archivos = file;
    }
  }

  saveImg() {
    const name = this.projectForm.get('name')?.value + '_folder';
    if (!this.archivos) {
      this.sweetAlertService.showWarningToast(
        'No se seleccionó ningún archivo.'
      );
      this.updateProject();
      return;
    }
    const file = this.archivos;
    this._mediasService.addMultimedia('Projects', name, file).subscribe(
      (res: Multimedias) => {
        if (res.ok) {
          if (!(res.data instanceof Array)) {
            this.sweetAlertService.showSuccessToast(
              'Imagen subida correctamente.'
            );
            this.updateProject(res.data);
          }
        }
      },
      (err: any) => {
        console.error('Error uploading media:', err);
        let errorMessage = 'Ocurrió un error al subir el archivo.';
        if (err && err.error && err.error.message) {
          errorMessage = err.error.message;
        }
        this.sweetAlertService.showErrorToast(errorMessage);
      }
    );
  }

  updateProject(data?: any) {
    const newProject = {
      name: this.projectForm.get('name')?.value || '',
      description: this.projectForm.get('description')?.value || '',
      logo: data?.url || this.urlImg,
    };
    this._projectService.updateProject(newProject, this._id).subscribe(
      (res: any) => {
        if (res.ok) {
          this.sweetAlertService.showSuccessToast(
            'Proyecto actualizado correctamente.'
          );
          this.dialogRef.close({
            reload: false,
            data: { _id: this._id, ...res.updated },
          });
        }
      },
      (err: any) => {
        console.error(err);
        this.sweetAlertService.showErrorToast(
          'Ocurrió un error al actualizar el proyecto.'
        );
      }
    );
  }

  eventData() {
    this.saveImg();
  }

  closeEvent() {
    this.dialogRef.close({
      reload: false,
    });
  }
}
