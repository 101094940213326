import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Conversations } from '@core/interfaces/conversations.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InteractionsMsgService {
  public urlBackEnd: String = environment.base_url;
  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';

  public urlConversations = 'conversations';
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('user', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getListConversations(): Observable<Conversations> {
    return this.http
      .get<Conversations>(`${this.urlBackEnd}/${this.urlConversations}`, {
        headers: this.getHeaders(),
      })
      .pipe();
  }
}
