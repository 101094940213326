import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/authentication/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  resetPasswordForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: [
        '',
        [Validators.required, Validators.email, this.emailDomainValidator],
      ],
    });
  }

  forgotPassword() {
    this.authService
      .forgotPasswordService(this.resetPasswordForm.value.email)
      .subscribe(
        (res) => {
          this.router.navigate(['/auth/login']);
        },
        (err) => {
          console.log('err', err);
        }
      );
  }

  emailDomainValidator(control: any) {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [_, domain] = email.split('@');
      if (domain.toLowerCase() !== 'fundacioncapital.org') {
        return { invalidDomain: true };
      }
    }
    return null;
  }
}
