<app-dialog
  [title]="'Formulario Perfil'"
  (saveEvent)="eventCreateOrEdit()"
  (closeEvent)="close()"
>
  <form
    id="validationform"
    [formGroup]="ProfileFormGroup"
    novalidate
    autocomplete="off"
  >
    <div class="row">
      <div class="tagus-form-group">
        <div class="col-lg-12 col-md-12">
          <label class="d-block mb-12 fw-semibold subtitle-card-color">
            Nombre
          </label>
          <mat-form-field appearance="fill">
            <i class="ri-file-text-line"></i>
            <mat-label>Escribe el Nombre</mat-label>
            <input
              matInput
              placeholder="Admin"
              formControlName="name_profile"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mt-20"></div>
    <div class="row note-box form-group">
      <mat-form-field appearance="fill">
        <mat-label class="fw-semibold">Permisos</mat-label>
        <mat-select formControlName="list_permissions" multiple>
          <mat-select-trigger>
            <mat-chip-listbox>
              <mat-chip
                *ngFor="
                  let permission of ProfileFormGroup.get('list_permissions')!
                    .value
                "
                [removable]="true"
                (removed)="onPermissionRemoved(permission)"
              >
                {{ permission }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-listbox>
          </mat-select-trigger>

          <mat-option
            *ngFor="let permission of permissionsList"
            [value]="permission"
          >
            {{ permission }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h3 class="mt-10 mb-10">Listado de Módulos</h3>
    <mat-divider></mat-divider>
    <mat-selection-list #modules>
      <mat-list-option
        *ngFor="let module of listModules"
        (click)="onOptionClick(module)"
        [selected]="isSelected(module)"
      >
        {{ module.name }}
      </mat-list-option>
    </mat-selection-list>
    <p>Options selected: {{ modules.selectedOptions.selected.length }}</p>
  </form>
</app-dialog>
