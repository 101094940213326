import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-phone-view',
  templateUrl: './line-phone-view.component.html',
  styleUrls: ['./line-phone-view.component.scss'],
})
export class LinePhoneView {
  @Input() chatDate: string = '';
  @Input() phoneHeight: string = '';
  @Input() phoneWidth: string = '';
  @Input() metaMessage: string = '';
  @Input() sentMessage: string = '';
  @Input() questions: any[] = [];
}
