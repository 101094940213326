<app-dialog
  class="custom-modal"
  [title]="'Proyectos'"
  (saveEvent)="eventData()"
  (closeEvent)="closeEvent()"
>
  <div class="modal-overlay"></div>
  <div class="modal-content">
    <div class="modal-body">
      <div class="modal-layout">
        <div class="image-container">
          <img [src]="urlImg" alt="Logo del Proyecto" />
        </div>

        <div class="form-container">
          <form [formGroup]="projectForm">
            <div class="tagus-form-group">
              <div class="col-lg-12 col-md-12">
                <label class="d-block mb-12 fw-semibold subtitle-card-color">
                  Nombre del Proyecto
                </label>
                <mat-form-field appearance="fill">
                  <i class="ri-file-text-line"></i>
                  <mat-label>Escribe el nombre del Proyecto</mat-label>
                  <input matInput formControlName="name" />
                </mat-form-field>
              </div>
            </div>
            <div class="tagus-form-group">
              <label class="d-block mb-12 fw-semibold subtitle-card-color">
                Descripción
              </label>
              <mat-form-field appearance="fill">
                <i class="ri-book-open-line"></i>
                <mat-label>Escribe la descripción del Proyecto</mat-label>
                <textarea
                  id="projectDescription"
                  class="form-control"
                  matInput
                  formControlName="description"
                  rows="2"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="tagus-form-group">
              <div class="col-lg-12 col-md-12">
                <ngx-dropzone
                  (change)="onSelect($event)"
                  class="dropzone"
                  [accept]="'image/*'"
                >
                  <ngx-dropzone-label>
                    Arrastra y suelta imágenes o haz clic aquí para
                    seleccionarlas.
                  </ngx-dropzone-label>
                  <ngx-dropzone-image-preview
                    *ngFor="let f of files"
                    [file]="f"
                    [removable]="true"
                    (removed)="onRemove(f)"
                  >
                    <ngx-dropzone-label>
                      {{ f.name }} ({{ f.type }})
                    </ngx-dropzone-label>
                  </ngx-dropzone-image-preview>
                </ngx-dropzone>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
