import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor() {}

  // Alerta básica
  showBasicAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'info',
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta de confirmación
  showConfirmAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    });
  }

  // Alerta de éxito
  showSuccessAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'success',
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta de error
  showErrorAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'error',
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta de advertencia
  showWarningAlert(title: string, text: string): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'warning',
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta personalizada
  showCustomAlert(options: SweetAlertOptions): Promise<any> {
    return Swal.fire(options);
  }

  // Alerta de carga
  showLoadingAlert(title: string, text: string): void {
    Swal.fire({
      title,
      text,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
  }

  // Cerrar alerta de carga
  closeLoadingAlert(): void {
    Swal.close();
  }

  // Alerta con temporizador
  showTimedAlert(title: string, text: string, timer: number): Promise<any> {
    return Swal.fire({
      title,
      text,
      icon: 'info',
      timer,
      timerProgressBar: true,
      showCloseButton: true,
      showConfirmButton: false,
    });
  }

  // Alerta de confirmación con acción
  showConfirmActionAlert(
    title: string,
    description: string,
    text: string,
    buttonConfirm: string
  ): Promise<any> {
    return Swal.fire({
      title,
      text: description,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: buttonConfirm,
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        return Swal.fire({
          title: 'ok!',
          text: text,
          icon: 'success',
        });
      } else {
        return Promise.resolve(null);
      }
    });
  }

  // Alerta de múltiples entradas
  showMultipleInputAlert(title: string): Promise<any> {
    return Swal.fire({
      title,
      html: `
        <input id="swal-input1" class="swal2-input" placeholder="Input 1">
        <input id="swal-input2" class="swal2-input" placeholder="Input 2">
      `,
      focusConfirm: false,
      preConfirm: () => {
        const input1 = (<HTMLInputElement>(
          document.getElementById('swal-input1')
        )).value;
        const input2 = (<HTMLInputElement>(
          document.getElementById('swal-input2')
        )).value;
        return { input1, input2 };
      },
    });
  }

  // Alerta de confirmación con entrada
  showConfirmInputAlert(title: string, inputPlaceholder: string): Promise<any> {
    return Swal.fire({
      title,
      input: 'text',
      inputPlaceholder: inputPlaceholder,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      preConfirm: (inputValue) => {
        if (!inputValue) {
          Swal.showValidationMessage('Por favor ingrese un valor');
        }
      },
    });
  }

  // Alerta de error con HTML
  showErrorAlertWithHtml(title: string, html: string): Promise<any> {
    return Swal.fire({
      title,
      html,
      icon: 'error',
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta con icono personalizado
  showCustomIconAlert(
    title: string,
    text: string,
    iconUrl: string
  ): Promise<any> {
    return Swal.fire({
      title,
      text,
      imageUrl: iconUrl,
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonText: 'Aceptar',
    });
  }

  // Alerta de imagen con texto
  showImageWithTextAlert(
    title: string,
    imageUrl: string,
    text: string
  ): Promise<any> {
    return Swal.fire({
      title,
      text,
      imageUrl,
      imageWidth: 100,
      imageHeight: 100,
      confirmButtonText: 'Aceptar',
    });
  }
  // Alerta de información
  showInfoAlert(title: string, text: string): Promise<any> {
    return this.showCustomAlert({
      title,
      text,
      icon: 'info',
      confirmButtonText: 'Aceptar',
    });
  }

  showSelectAlert(title: string, text: string, options: any[]): Promise<any> {
    return Swal.fire({
      title,
      text,
      input: 'select',
      inputOptions: options,
      inputPlaceholder: 'Selecciona una opción',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve();
          } else {
            resolve('Debes seleccionar una opción');
          }
        });
      },
    });
  }
}
