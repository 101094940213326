<header
  class="header-area bg-white border-bottom-radius box-shadow mb-15"
  [ngClass]="{ active: isToggled, sticky: isSticky }"
  [class.component-dark-theme]="themeService.isDark()"
  [class.right-sidebar]="themeService.isRightSidebar()"
  [class.hide-sidebar]="themeService.isHideSidebar()"
  [class.dark-header]="themeService.isHeaderDark()"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.card-border-radius]="themeService.isCardBorderRadius()"
  [class.rtl-enabled]="themeService.isRTLEnabled()"
>
  <div class="d-flex align-items-center justify-content-space-between">
    <div class="header-left-side d-flex align-items-center">
      <div
        class="burger-menu"
        (click)="toggle()"
        [ngClass]="{ active: isToggled }"
      >
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
      </div>
    </div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="languageMenu"
      class="btn p-0 border-none rounded-circle position-relative"
    >
      <!-- Aseguramos que el logo y nombre del proyecto no se actualicen inesperadamente -->
      <img
        *ngIf="projectSelect?.logo"
        [src]="projectSelect.logo"
        alt="{{ projectSelect?.name }}"
      />
      <span class="caret">{{ projectSelect?.name }}</span>
    </button>
    <mat-menu
      class="language-menu-dropdown"
      #languageMenu="matMenu"
      xPosition="after"
    >
      <ul class="m-0" *ngFor="let project of listProjects">
        <li>
          <button
            type="button"
            class="d-flex align-items-center border-none"
            (click)="updateProject(project)"
          >
            <img [src]="project.logo" [alt]="project.name" />
            {{ project.name }}
          </button>
        </li>
      </ul>
    </mat-menu>
    <div class="header-center">
      <div class="header-image">
        <img src="assets/img/ConHector/Conhector-horizontal.png" alt="Con" />
      </div>
    </div>
    <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
      <li class="d-none d-lg-block">
        <div class="date-btn title-color fw-semibold position-relative">
          <i class="flaticon-calendar"></i>
          {{ formattedDate }}
        </div>
      </li>
    </ul>
  </div>
</header>
