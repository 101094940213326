import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User, Users } from '@core/interfaces/User.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import { UsersService } from '@core/services/configurations/users.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { MultimediasService } from '@core/services/programs/multimedias.service';
import Swal from 'sweetalert2';
import { DialogImageCropperComponent } from '../dialog/dialog-image-cropper/dialog-image-cropper.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public infoUser: User = {};
  public idUser: string = '';
  public hide = true;
  public preview: any;
  public archivos: any = [];
  public files: File[] = [];
  public imagePreview: string | ArrayBuffer | null = '';
  public croppedImage: any;

  public userForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(150)]],
    last_name: ['', Validators.required],
    phone: ['', Validators.required],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._%+-]+@fundacioncapital.org$'),
      ],
    ],
    project: ['', Validators.required],
    password: [''],
    newPassword: [''],
    confirmPassword: [''],
  });

  constructor(
    private fb: FormBuilder,
    public _userService: UsersService,
    private _authService: AuthService,
    private mediaService: MultimediasService,
    public themeService: CustomizerSettingsService,
    public dialog: MatDialog
  ) {
    this.idUser = localStorage.getItem('user_conhector') || '';
  }

  ngOnInit(): void {
    this.getInfoUser();
  }

  forgotPassword() {
    this._authService
      .forgotPasswordService(this.userForm.value.email || '')
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            title:
              'Se ha enviado un correo electrónico para restablecer la contraseña',
            showConfirmButton: false,
            timer: 1500,
          });
        },
        (err) => {
          console.log('err', err);
        }
      );
  }

  getInfoUser() {
    this._userService.getInfoUser(this.idUser).subscribe(
      (res: Users) => {
        if (res.ok) {
          if (!Array.isArray(res.data)) {
            this.infoUser = res.data;
            this.userForm.setValue({
              name: this.infoUser.name || '',
              last_name: this.infoUser.last_name || '',
              phone: this.infoUser.phone || '',
              email: this.infoUser.email || '',
              project: this.infoUser.project || '',
              password: '',
              newPassword: '',
              confirmPassword: '',
            });
          }
        } else if (!res.ok) {
          Swal.fire({
            icon: 'warning',
            title: 'No hemos podido obtener la información del usuario',
          });
        }
      },
      (err: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al obtener la información del usuario',
          text: err.error.message,
        });
      }
    );
  }

  updateUser() {
    let updateData: User = {
      name: this.userForm.value.name || '',
      last_name: this.userForm.value.last_name || '',
      phone: this.userForm.value.phone || '',
      email: this.userForm.value.email || '',
      project: this.userForm.value.project || '',
    };
    this._userService.UpdateUser(this.idUser, updateData).subscribe(
      (res: any) => {
        if (res.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Usuario actualizado correctamente',
            showConfirmButton: false,
            timer: 1500,
          });
        } else if (!res.ok) {
          Swal.fire({
            icon: 'warning',
            title: 'No hemos podido actualizar el usuario',
          });
        }
      },
      (err: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar el usuario',
          text: err.error.message,
        });
      }
    );
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imagePreview = e.target.result;
      this.openImageCropperDialog();
    };
    reader.readAsDataURL(file);
  }

  openImageCropperDialog() {
    const dialogRef = this.dialog.open(DialogImageCropperComponent, {
      width: '500px',
      data: { imagePreview: this.imagePreview as string },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.croppedImage = result;
      }
    });
  }

  uploadImgPerfil() {
    if (this.croppedImage) {
      const data = new FormData();
      data.append('idPrefil', this.idUser);
      data.append('file', this.dataURLtoBlob(this.croppedImage));
      this.mediaService.sendPost(data).subscribe(
        (res: any) => {
          Swal.fire(
            'Éxito',
            'Imagen de perfil actualizada correctamente',
            'success'
          );
          location.reload();
        },
        (err: any) => {
          Swal.fire('Ops!!!', 'Se ha presentado un error', 'error');
          console.log(err);
        }
      );
    }
  }

  dataURLtoBlob(dataurl: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}
