<div
  class="h-100vh pt-50 pb-50"
  [class.component-dark-theme]="themeService.isDark()"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.card-border-radius]="themeService.isCardBorderRadius()"
>
  <div class="d-table">
    <div class="d-table-cell">
      <div
        class="logout-box text-center bg-white border-radius ms-auto me-auto"
      >
        <div class="logo">
          <img src="assets/img/icons/2_pajaro.png" alt="logo" />
        </div>
        <div class="logo white-logo">
          <img src="assets/img/white-logo.png" alt="logo" />
        </div>
        <h5 class="fw-semibold mb-12">Estás desconectado</h5>
        <p>
          Gracias por utilizar
          <span class="fw-semibold">CON HÉCTOR</span>
          admin
        </p>
        <a
          mat-flat-button
          class="tagus d-block fw-semibold"
          routerLink="/authentication/login"
        >
          Sign In
        </a>
      </div>
    </div>
  </div>
</div>
