import { Component, OnInit } from '@angular/core';
import {
  Conversation,
  Participant,
} from '@core/interfaces/conversations.interface';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { InteractionsMsgService } from '@core/services/interactions-msg/interactions-msg.service';

@Component({
  selector: 'app-interactions-msg',
  templateUrl: './interactions-msg.component.html',
  styleUrls: ['./interactions-msg.component.scss'],
})
export class InteractionsMsgComponent implements OnInit {
  public title: string = 'Mensajes';
  public listMessages: Conversation[] = [];
  public listParticipant: Participant[] = [];
  public selectedMessages: any[] = [];
  public selectedParticipant: string = '';
  constructor(
    public themeService: CustomizerSettingsService,
    private interactionsMsgService: InteractionsMsgService
  ) {}

  ngOnInit(): void {
    this.getListConversations();
  }

  getListConversations() {
    this.interactionsMsgService
      .getListConversations()
      .subscribe((data: any) => {
        this.listMessages = data.data;

        this.listMessages.forEach((conversation) => {
          const participant = conversation.participant;
          const index = this.listParticipant.findIndex(
            (participantItem: any) => participantItem.id === participant.id
          );

          if (index === -1) {
            this.listParticipant.push(participant);
          }
        });
      });
  }

  selectParticipantEvent(event: any) {
    const conversation = this.listMessages.find(
      (conv) => conv.participant.id === event.id
    );

    this.selectedParticipant = event.name;
    this.selectedMessages = conversation ? conversation.messages : [];
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}
