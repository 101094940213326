<app-card title="Listado de Match">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="tagus-search-box search-input mb-20">
      <input
        matInput
        type="text"
        [formControl]="myControl"
        [matAutocomplete]="auto"
        class="input-search border-radius"
        placeholder="Search here"
      />
      <button type="submit"><i class="ri-search-line"></i></button>
    </div>
    <div class="tagus-form-group">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
          (onSelectionChange)="optionSelected(option)"
        >
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <mat-list>
    <ng-container *ngFor="let info of dataGroup; let i = index">
      <div mat-subheader>
        <strong>Grupo: {{ info.group.name }}</strong>
        <button
          mat-icon-button
          class="blue-color"
          (click)="addNewFirstLevel(info, info.levels)"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="chip-container">
        <ng-container *ngFor="let item of info.levels; let j = index">
          <mat-chip-listbox (click)="selectDiagram(item, info, j)">
            <mat-chip-option
              color="accent"
              [selected]="selectedChips[info.group.name] === j"
            >
              Position {{ j + 1 }}
            </mat-chip-option>
          </mat-chip-listbox>
        </ng-container>
      </div>
      <mat-divider></mat-divider>
    </ng-container>

    <app-card *ngIf="activeDiagram" title="Diagrama" [breadCrumb]="false">
      <app-diagram
        class="full-diagram"
        [dataLevels]="selectedDiagram"
        [match]="activeMatch"
        (reloadData)="changeInfo($event)"
      ></app-diagram>
    </app-card>
  </mat-list>
</app-card>
