<!-- (click)="" -->

<app-card
  title="Listado de Usuarios"
  (action)="openCreateUserDialog()"
  [titleButton]="'Nuevo Usuario'"
>
  <app-table-data
    [data]="listUser"
    [dataSource]="dataSource"
    [columns]="displayedColumns"
    [pageSizeOptions]="[10, 15, 20]"
    [editButton]="true"
    [deleteButton]="true"
    [viewButton]="false"
    [stateButton]="true"
    (editEvent)="editUser($event)"
    (stateEvent)="stateUser($event)"
    (deleteEvent)="deleteUser($event)"
  ></app-table-data>
</app-card>
