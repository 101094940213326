import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Inidicatives } from '@core/interfaces/indicatives.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import {
  catchError,
  finalize,
  Observable,
  retry,
  shareReplay,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndicativesService {
  public urlBackEnd: String = environment.base_url;
  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';

  public urlIndicative = 'indicatives';
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getIndicatives(): Observable<Inidicatives> {
    return this.http.get<Inidicatives>(
      `${this.urlBackEnd}/${this.urlIndicative}`,
      { headers: this.getHeaders() }
    );
  }

  getIndicative(id: string): Observable<Inidicatives> {
    return this.http
      .get<Inidicatives>(`${this.urlBackEnd}/${this.urlIndicative}/${id}`, {
        headers: this.getHeaders(),
      })
      .pipe(
        retry(1),
        timeout(5000),
        shareReplay(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en getIndicative';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getIndicative: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(errorMessage);
        })
      );
  }

  createIndicative(data: any): Observable<Inidicatives> {
    return this.http.post<Inidicatives>(
      `${this.urlBackEnd}/${this.urlIndicative}`,
      data,
      {
        headers: this.getHeaders(),
      }
    );
  }

  updateIndicative(id: string, data: any): Observable<Inidicatives> {
    return this.http.put<Inidicatives>(
      `${this.urlBackEnd}/${this.urlIndicative}/${id}`,
      data,
      { headers: this.getHeaders() }
    );
  }

  deleteIndicative(id: string): Observable<Inidicatives> {
    return this.http.delete<Inidicatives>(
      `${this.urlBackEnd}/${this.urlIndicative}/${id}`,
      {
        headers: this.getHeaders(),
      }
    );
  }
}
