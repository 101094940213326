import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@core/interfaces/modules.interface';
import { User, Users } from '@core/interfaces/User.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public urlBackend: string = environment.base_url;
  public headers: any;
  public UrlUser = 'users';

  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';

  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getInfoUser(): Observable<Users> {
    const headers = this.getHeaders();
    return this.http
      .get<Users>(`${this.urlBackend}/${this.UrlUser}/${this.idUser}`, {
        headers,
      })
      .pipe(
        retry(3),

        // Manejo de errores después de los intentos fallidos
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de la lista de proyectos';

          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getInfoUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }

          // Lanzar el error después de los 3 intentos fallidos
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  getUsers(): Observable<Users> {
    const headers = this.getHeaders();
    return this.http
      .get<Users>(`${this.urlBackend}/${this.UrlUser}`, { headers })
      .pipe(
        retry(3),

        // Manejo de errores después de los intentos fallidos
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de la lista de proyectos';

          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getInfoUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }

          // Lanzar el error después de los 3 intentos fallidos
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  createUser(user: User): Observable<User> {
    const headers = this.getHeaders();

    return this.http
      .post<User>(`${this.urlBackend}/${this.UrlUser}`, user, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de createUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  UpdateUser(id: string, user: User): Observable<Users> {
    const headers = this.getHeaders();
    id = id ?? this.idUser;
    const params = new HttpParams().set('userId', id);
    return this.http
      .patch<Users>(`${this.urlBackend}/${this.UrlUser}`, user, {
        params: params,
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de UpdateUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  registerProfileUser(id: string, profile: string): Observable<User> {
    const headers = this.getHeaders();

    let params = new HttpParams().set('id', id).set('profile', profile);

    return this.http
      .get<User>(`${this.urlBackend}/${this.UrlUser}/addProfile`, {
        params: params,
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de registerProfileUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  filterProfileUser(profile: string): Observable<Users> {
    const headers = this.getHeaders();

    let params = new HttpParams().set('profile', profile);

    return this.http
      .get<Users>(`${this.urlBackend}/${this.UrlUser}/filterProfile`, {
        params: params,
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de filterProfileUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  changeStatusUser(id: string): Observable<User> {
    const headers = this.getHeaders();

    return this.http
      .get<User>(`${this.urlBackend}/${this.UrlUser}/changeStatus/${id}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de changeStatusUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  deleteUser(id: string): Observable<any> {
    const headers = this.getHeaders();

    return this.http
      .delete(`${this.urlBackend}/${this.UrlUser}/${id}`, { headers: headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud deleteUser';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de deleteUser: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }
}
