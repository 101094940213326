import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';

@Component({
  selector: 'app-fm-sidebar',
  templateUrl: './fm-sidebar.component.html',
  styleUrls: ['./fm-sidebar.component.scss'],
})
export class FmSidebarComponent implements OnChanges {
  @Input() folders: string[] = [];
  @Output() folderSelect: EventEmitter<any> = new EventEmitter<string>();

  constructor(public themeService: CustomizerSettingsService) {}

  ngOnChanges(changes: SimpleChanges): void {}

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }

  selectFolder(folder: string) {
    this.folderSelect.emit(folder);
  }
}
