import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { User } from '@core/interfaces/User.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { ToggleService } from 'src/app/core/services/toggle.service';
import { UsersService } from '@core/services/configurations/users.service';
import { CustomizerSettingsService } from 'src/app/core/services/customizer-settings.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;
  isToggled = false;
  public userInfo: User = {};

  public imgUser: string = '';
  public nameUser: string = '';
  public lastNameUser: string = '';

  constructor(
    public router: Router,
    private toggleService: ToggleService,
    private authStateService: AuthStateService,
    public themeService: CustomizerSettingsService
  ) {
    this.toggleService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  ngOnInit(): void {
    this.authStateService.nameUser$.subscribe((name) => {
      this.nameUser = name || '';
    });
    this.authStateService.lastNameUser$.subscribe((lastName) => {
      this.lastNameUser = lastName || '';
    });

    this.authStateService.imgUser$.subscribe((img) => {
      this.imgUser = img || '';
    });
  }

  logout() {
    this.authStateService.lockScreenClean();
    this.router.navigate(['/lockscreen']);
  }

  toggle() {
    this.toggleService.toggle();
  }

  toggleSidebarTheme() {
    this.themeService.toggleSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }
}
