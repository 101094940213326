<app-card
  title="Listado de Programas"
  [link]="'/programs/create_program'"
  [titleRouter]="titleButton"
>
  <app-table-data
    [data]="listPrograms"
    [dataSource]="dataSource"
    [columns]="displayedColumns"
    [pageSizeOptions]="[10, 20, 50, 100, 200]"
    [totalItems]="totalItems"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [viewButton]="true"
    [editButton]="true"
    [deleteButton]="true"
    (editEvent)="editProgram($event)"
    (viewEvent)="getListStepPrograms($event)"
    (pageChange)="onPageChange($event)"
    (deleteEvent)="deleteProgram($event)"
  >
    >
  </app-table-data>
</app-card>
