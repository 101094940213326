import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Program, Programs } from '@core/interfaces/Programs.interface';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { SearchLevelByPosition } from '@core/interfaces/searchLevel.interface';
import { FilterService } from '@core/services/filters/filter.service';
import { MatchService } from '@core/services/programs/match.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';
import { Observable, Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-add-level',
  templateUrl: './dialog-add-level.component.html',
  styleUrls: ['./dialog-add-level.component.scss'],
})
export class DialogAddLevelComponent implements OnInit, OnDestroy {
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  public loadData = new EventEmitter<any>();
  /** Paginator de la tabla. */
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  displayedColumns: string[] = ['program_name', 'action'];
  public listPrograms: any[] = [];
  /** Número total de elementos. */
  public totalItems?: number;
  public pageSize: number = 10;
  public pageIndex = 1;
  /*  La configuracion de botones de filtrado. */
  filterConfigs: FilterConfig[] = [];

  /** Subscripcion al la eleccion del filtrado */
  private filterSubscription: Subscription | undefined;
  /** Respuesta del servicio con lso filtros escogidos*/
  public dataFilters: any = {};

  public match: any;
  public level: number = 0;
  public position: string = '';
  public program: any;

  public dataFilterPosition: any;
  public typeEvent = '';

  myControl = new FormControl<string | any>('');
  filteredOptions!: Observable<any[]>;
  dataSource = new MatTableDataSource<Program>(this.listPrograms);

  constructor(
    private programsService: ProgramsService,
    private matchService: MatchService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddLevelComponent>,
    private filterService: FilterService,
    private sweetAlertService: SweetAlertService
  ) {
    if (data) {
      this.match = data.Match;
      this.level = data.level;
      this.position = data.position;
      this.typeEvent = data.isUpdate;

      if (this.typeEvent !== 'returnsId') {
        this.searchLevelByPosition();
      }
    }
  }

  ngOnInit(): void {
    this.getListPrograms();
  }

  /** Obtiene la lista de programas con paginación. */
  getListPrograms(filters?: any) {
    this.programsService
      .getListPrograms(this.pageIndex, this.pageSize)
      .subscribe(
        (resp: Programs) => {
          if (resp.ok) {
            if (Array.isArray(resp.data)) {
              this.listPrograms = resp.data;
              this.dataSource.data = this.listPrograms;
              this.totalItems = resp.data.length;
              this.dataSource = new MatTableDataSource(this.listPrograms);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  toggleProgramSelection(program: any): void {
    this.program = program;
    this.typeEventService()
      .then((res) => {
        this.close(res);
      })
      .catch((err) => {
        console.error('Error during typeEventService execution:', err);
      });
  }

  registerNewLevel(): void {
    let childrenLength = 1;
    if (this.dataFilterPosition.children) {
      childrenLength = this.dataFilterPosition.children.length + 1;
    }

    let dataRegister = {
      level: this.dataFilterPosition.level,
      position: this.dataFilterPosition.position,
      data: {
        level: this.dataFilterPosition.level + 1,
        position: `${this.dataFilterPosition.position}.${childrenLength}`,
        program: this.program._id,
      },
    };
    this.matchService
      .registerNewLevel(this.match._id || this.match, dataRegister)
      .subscribe(
        (res) => {
          if (res.ok) {
            this.sweetAlertService.showConfirmAlert(
              '👌',
              ' Se ha registrado el nuevo Match'
            );
            this.close({ method: 'update', response: res.match });
          } else {
            console.error('Registration failed');
          }
        },
        (err) => {
          console.log(err);
          Swal.fire(
            'Se ha presentado un error al registrar el match',
            '',
            'error'
          );
        }
      );
  }

  updateLevel(): Promise<any> {
    return new Promise((resolve, reject) => {
      let dataJson = {
        level: this.level,
        position: this.position,
        data: {
          program: this.program._id,
        },
      };

      this.matchService
        .updateProgram(this.match._id || this.match, dataJson)
        .subscribe(
          (res: any) => {
            if (res.ok) {
              this.sweetAlertService.showSuccessAlert(
                '👌',
                'Se ha actualizado el match'
              );
              resolve({ method: 'update', response: res.match });
            } else {
              reject('Update failed');
            }
          },
          (err: any) => {
            console.log(err);
            Swal.fire(
              'Se ha presentado un error al registrar el match',
              '',
              'error'
            );
            reject(err);
          }
        );
    });
  }

  searchLevelByPosition() {
    let data = {
      targetPosition: this.position,
      targetLevel: this.level,
      levels: this.match?.levels,
    };

    this.matchService.searchLevelAndPosition(data).subscribe(
      (res: SearchLevelByPosition) => {
        if (res.ok) {
          this.dataFilterPosition = res.node;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    this.getListPrograms(this.dataFilters);
  }

  async typeEventService(): Promise<void> {
    switch (this.typeEvent) {
      case 'update':
        const matchUpdate = await this.updateLevel();
        return matchUpdate;
      case 'notUpdate':
        const match = await this.registerNewLevel();
        return match;

      case 'returnsId':
        await this.registerNewFirstLevel();
        break;
    }
  }

  registerNewFirstLevel(): Promise<void> {
    return new Promise((resolve, reject) => {
      let idMatch = this.data.body.match;

      let infoBody = {
        level: this.data.body.level,
        position: this.data.body.position,
        data: {
          level: this.data.body.level,
          position: this.data.body.position,
          program: this.program._id,
        },
      };

      this.matchService.addLevelFrist(idMatch, infoBody).subscribe(
        (res: any) => {
          if (res.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Se ha registrado el nuevo Match',
              iconHtml: '<i class="ri-check-line" style="color: green;"></i>',
              customClass: {
                popup: 'swal-custom-popup',
                title: 'swal-custom-title',
                htmlContainer: 'swal-custom-content',
              },
              showConfirmButton: false,
              timer: 3000,
              backdrop: false,
              toast: true,
              position: 'top-end',
              background: '#ffffff',
              color: '#1d2c4d',
              padding: '1rem',
              width: '20rem',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            resolve();
          } else {
            reject('First level registration failed');
          }
        },
        (err: any) => {
          console.log(err);
          Swal.fire(
            'Se ha presentado un error al registrar el match',
            '',
            'error'
          );
          reject(err);
        }
      );
    });
  }

  close(res?: any): void {
    this.dialogRef.close(res);
  }
}
