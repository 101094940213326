import { Component, OnInit } from '@angular/core';
import { Multimedia, Multimedias } from '@core/interfaces/multimedia.interface';

import { MultimediasService } from '@core/services/multimedias.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';

@Component({
  selector: 'app-multimedias',
  templateUrl: './multimedias.component.html',
  styleUrls: ['./multimedias.component.scss'],
})
export class MultimediasComponent implements OnInit {
  public listFolder: string[] = [];
  public selectFolder: string = '';
  public listFiles: Multimedia[] = [];

  constructor(
    private sweetAlertService: SweetAlertService,
    private multimediasService: MultimediasService
  ) {}

  ngOnInit(): void {
    this.getFolders();
  }

  getFolders() {
    this.multimediasService.getFoldersMultimedias().subscribe(
      (res: any) => {
        if (res.ok) {
          this.listFolder = res.data;
        } else {
          this.sweetAlertService.showErrorAlert(
            'Error',
            'Formato de datos incorrecto.'
          );
        }
      },
      (err) => {
        this.sweetAlertService.showErrorAlert(
          'Error',
          err.error.message || 'Error al obtener carpetas.'
        );
      }
    );
  }

  getMultimediasByFolder(folder: string) {
    this.multimediasService.getMultimedias(folder).subscribe(
      (res: Multimedias) => {
        if (res.ok) {
          if (Array.isArray(res.data)) {
            this.listFiles = res.data;
          }
        } else {
          this.sweetAlertService.showErrorAlert(
            'Error',
            'Formato de datos incorrecto.'
          );
        }
      },
      (err: any) => {
        this.sweetAlertService.showErrorAlert(
          'Error',
          err.error.message || 'Error al obtener multimedias.'
        );
      }
    );
  }

  folderSelect(event: string) {
    this.getMultimediasByFolder(event);
  }
}
