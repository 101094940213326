import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  loadingStatus = this.loading.asObservable();

  constructor() {}

  setLoading(loading: boolean) {
    this.loading.next(loading);
  }

  getLoading(): boolean {
    return this.loading.value;
  }
}
