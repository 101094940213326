import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private userIdSubject = new BehaviorSubject<string | null>(
    this.getCookie('userId')
  );
  private tokenSubject = new BehaviorSubject<string | null>(
    this.getCookie('token')
  );
  private nameUserSubject = new BehaviorSubject<string | null>(
    this.getCookie('nameUser')
  );
  private lastNameUserSubject = new BehaviorSubject<string | null>(
    this.getCookie('lastNameUser')
  );
  private emailUserSubject = new BehaviorSubject<string | null>(
    this.getCookie('emailUser')
  );
  private profileUserSubject = new BehaviorSubject<string | null>(
    this.getCookie('roleUser')
  );
  private imgUserSubject = new BehaviorSubject<string | null>(
    this.getCookie('imgUser')
  );
  private projectSubject = new BehaviorSubject<string | null>(
    this.getCookie('project')
  );

  userId$ = this.userIdSubject.asObservable();
  token$ = this.tokenSubject.asObservable();
  nameUser$ = this.nameUserSubject.asObservable();
  lastNameUser$ = this.lastNameUserSubject.asObservable();
  emailUser$ = this.emailUserSubject.asObservable();
  roleUser$ = this.profileUserSubject.asObservable();
  imgUser$ = this.imgUserSubject.asObservable();
  project$ = this.projectSubject.asObservable();

  constructor() {}

  setUserId(id: string | null) {
    this.userIdSubject.next(id);
    this.setCookie('userId', id, 0);
  }

  setToken(token: string | null) {
    this.tokenSubject.next(token);
    this.setCookie('token', token, 0);
  }

  setNameUser(name: string | null) {
    this.nameUserSubject.next(name);
    this.setCookie('nameUser', name, 0);
  }

  setLastNameUser(lastName: string | null) {
    this.lastNameUserSubject.next(lastName);
    this.setCookie('lastNameUser', lastName, 0);
  }

  setEmailUser(email: string | null) {
    this.emailUserSubject.next(email);
    this.setCookie('emailUser', email, 0);
  }

  setProfileUser(profiles: string[] | null) {
    this.profileUserSubject.next(profiles ? profiles.join(',') : null);
    this.setCookie('profileUser', profiles ? profiles.join(',') : null, 0);
  }

  setImgUser(img: string | null) {
    this.imgUserSubject.next(img);
    this.setCookie('imgUser', img, 0);
  }

  setProject(project: string | null) {
    this.projectSubject.next(project);
    this.setCookie('project', project, 0);
  }

  lockScreenClean() {
    this.clearUserData();
    this.clearCookies();
  }

  clearAuthData() {
    this.clearUserData();
    this.clearCookies();
  }

  private clearUserData() {
    this.tokenSubject.next(null);
    this.nameUserSubject.next(null);
    this.lastNameUserSubject.next(null);
    this.profileUserSubject.next(null);
    this.imgUserSubject.next(null);
  }

  private setCookie(name: string, value: string | null, maxAgeSeconds: number) {
    let cookieString = `${name}=${value ?? ''}; path=/;`;
    if (value) {
      cookieString += `SameSite=Strict;`;
      cookieString += `Secure;`;
      if (maxAgeSeconds > 0) {
        cookieString += `max-age=${maxAgeSeconds};`;
      }
    } else {
      cookieString += 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    }
    document.cookie = cookieString;
  }

  private getCookie(name: string): string | null {
    const match = document.cookie.match(
      new RegExp('(^| )' + name + '=([^;]+)')
    );
    return match ? match[2] : null;
  }

  private clearCookies() {
    this.setCookie('token', null, 0);
    this.setCookie('nameUser', null, 0);
    this.setCookie('lastNameUser', null, 0);
    this.setCookie('roleUser', null, 0);
    this.setCookie('imgUser', null, 0);
  }
}
