import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { FilterConfig } from '@core/interfaces/filters.interface';
import {
  Program,
  Programs,
  ProgramsFilter,
  Step,
} from '@core/interfaces/Programs.interface';
import { FilterService } from '@core/services/filters/filter.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';

import { DialogViewProgramComponent } from '@modules/programs/components/dialog-view-program/dialog-view-program.component';
import { AuthStateService } from '@core/states/auth-state.service';
import { Subscription } from 'rxjs';

/**
 * Componente para listar programas con paginación y filtros.
 */
@Component({
  selector: 'app-list-programs',
  templateUrl: './list-programs.component.html',
  styleUrls: ['./list-programs.component.scss'],
})
export class ListProgramsComponent implements OnInit, AfterViewInit {
  public titleButton = 'Crear Programa';
  private filterSubscription: Subscription | undefined;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  public pageIndex: number = 1;
  public pageSize: number = 50;
  public totalItems?: number;
  displayedColumns: string[] = [
    'program_name',
    'program_type',
    'category',
    'menu_option',
    'state',
    'action',
  ];
  filterConfigs: FilterConfig[] = [];
  public listPrograms: Program[] = [];

  public cachedData: { [key: number]: Program[] } = {};
  public filters: ProgramsFilter = {};
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public dataFilters: ProgramsFilter = {};
  dataSource = new MatTableDataSource<Program>(this.listPrograms);

  public listSteps: Step[] = [];

  /**
   * Constructor del componente.
   */
  constructor(
    private router: Router,
    public dialog: MatDialog,
    public programsService: ProgramsService,
    private filterService: FilterService,
    public authStateService: AuthStateService,
    public themeService: CustomizerSettingsService
  ) {}

  ngOnInit(): void {
    this.authStateService.project$.subscribe((project) => {
      this.programsService.getListFiltersPrograms().subscribe((resp: any) => {
        const types: string[] = Object.keys(resp.filters);

        // Configuración de los filtros
        this.filterConfigs = [
          {
            name: 'Tipo',
            icon: 'ri-list-check-2',
            options: types,
            dbName: 'program_type',
          },
          {
            name: 'Categoria',
            icon: 'ri-bar-chart-horizontal-fill',
            options: [],
            dbName: 'category',
          },
          {
            name: 'Estado',
            icon: 'ri-bard-line',
            options: [],
            dbName: 'state',
            pipe: 'state',
          },
        ];
        this.filters = resp.filters;
        // Pasar la configuración de filtros al servicio
        this.filterService.setFilterConfigs(this.filterConfigs, resp.filters);
      });
    });
    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe(
      (filters) => {
        if (!filters.program_type) {
          this.dataFilters = {};
          this.filterService.setFilterConfigs(this.filterConfigs, this.filters);
          this.getListPrograms();
        }
        const selectedCategoryData = this.filterService.getFilterDataByCategory(
          filters.program_type
        );
        if (filters.program_type) {
          if (selectedCategoryData) {
            this.filterConfigs.find(
              (config) => config.name === 'Categoria'
            )!.options = selectedCategoryData.Subcategory ?? '';
            this.filterConfigs.find(
              (config) => config.name === 'Estado'
            )!.options = selectedCategoryData.state ?? '';
          }
        }

        for (const itemFilter in filters) {
          if (itemFilter !== 'searchValue') {
            if (selectedCategoryData[itemFilter] || itemFilter === 'category') {
              const value = selectedCategoryData[itemFilter]?.find(
                (value: any) => value === filters[itemFilter]
              );
              if (itemFilter === 'category') {
                filters[itemFilter] = this.filters[
                  filters.program_type
                ].Subcategory?.find(
                  (category: any) => category.name === filters.category
                )?.id;
                if (!filters[itemFilter]) {
                  delete filters[itemFilter];
                }
              } else if (value) {
                filters[itemFilter] = value;
              } else {
                delete filters[itemFilter];
              }
            }
          }
        }
        // Obtener la lista de programas paginada con los filtros
        this.getListPrograms(filters);
        this.dataFilters = filters;
      }
    );

    // Obtener la lista inicial de programas paginada
    this.getListPrograms();
  }

  /**
   * Método que se ejecuta después de la inicialización de la vista.
   */
  ngAfterViewInit(): void {
    if (this.paginator) {
      // Manejar la paginación de la tabla
      this.paginator.page.subscribe((event: PageEvent) => {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Verificar si los datos ya están en caché antes de hacer la consulta
        if (
          this.cachedData[this.pageIndex] &&
          this.cachedData[this.pageIndex].length === this.pageSize
        ) {
          this.listPrograms = this.cachedData[this.pageIndex];
          this.dataSource.data = this.listPrograms;
        } else {
          this.getListPrograms();
        }
      });
    }
  }

  getListPrograms(filters?: any) {
    this.programsService
      .getListProgramsPaginate(this.pageIndex, this.pageSize, filters)
      .subscribe(
        (resp: any) => {
          if (resp.ok) {
            if (Array.isArray(resp.results.data)) {
              this.listPrograms = resp.results.data;
              this.dataSource.data = this.listPrograms;
              this.totalItems = resp.results.pagination.total;
              this.dataSource = new MatTableDataSource(this.listPrograms);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  /**
   * Método de destrucción del componente.
   */
  ngOnDestroy(): void {
    // Destruir la suscripción
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  /**
   * Navega a la página de edición de un programa.
   * @param event Evento con los datos del programa a editar.
   */
  editProgram(event: any) {
    this.router.navigate(['programs/edit_program'], {
      queryParams: { id: event._id },
    });
  }

  /**
   * Navega al dialogo de vista del programa.
   * @param event Evento con los datos del programa a ver.
   */
  openViewProgramDialog(data: any): void {
    const dialogRef = this.dialog.open(DialogViewProgramComponent, {
      width: '70vw',
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  getListStepPrograms(event: any) {
    this.programsService.getProgramById(event._id).subscribe(
      (resp: Programs) => {
        if (resp.ok) {
          if (!Array.isArray(resp.data)) {
            this.openViewProgramDialog({
              program: event,
              listSteps: resp.data,
            });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteProgram(event: any) {}

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    this.getListPrograms(this.dataFilters);
  }
}
