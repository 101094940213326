import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Interfaz que define la estructura de un breadcrumb.
 */
interface Breadcrumb {
  label: string;
  url: string;
  icon?: string;
  subtitle?: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements OnInit, OnChanges, OnDestroy {
  breadcrumbs: Breadcrumb[] = [];
  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // Suscribirse a los eventos de navegación del router para actualizar los breadcrumbs
    this.routerSubscription = this.router.events.subscribe(() => {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    });
  }

  /**
   * Detecta cambios en las propiedades del componente.
   * @param changes - Los cambios detectados en las propiedades del componente.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['router']) {
      this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    }
  }

  /**
   * Inicializa el componente y configura la suscripción a los eventos de navegación del router.
   */
  ngOnInit(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      });
  }

  /**
   * Limpia las suscripciones al destruir el componente.
   */
  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  /**
   * Crea una lista de breadcrumbs a partir de la ruta activa.
   * @param route - La ruta activada.
   * @param url - La URL acumulativa actual.
   * @param breadcrumbs - La lista acumulativa de breadcrumbs.
   * @returns La lista de breadcrumbs.
   */
  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== 'primary') {
        continue;
      }

      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      const fullURL = url + `/${routeURL}`;

      if (!child.snapshot.data['title']) {
        return this.createBreadcrumbs(child, fullURL, breadcrumbs);
      }

      // Añadir el primer breadcrumb si no está añadido
      if (breadcrumbs.length === 0 && child.snapshot.data['firstBreadcrumb']) {
        breadcrumbs.push({
          label: child.snapshot.data['firstBreadcrumb'].title,
          url: child.snapshot.data['firstBreadcrumb'].url,
          icon: child.snapshot.data['firstBreadcrumb'].icon,
        });
      }

      // Añadir los breadcrumbs de los padres
      if (child.snapshot.data['parents']) {
        for (const parent of child.snapshot.data['parents']) {
          breadcrumbs.push({
            label: parent.title,
            url: parent.url,
            icon: parent.icon,
          });
        }
      }

      const breadcrumb: Breadcrumb = {
        label: child.snapshot.data['title'],
        url: fullURL,
        icon: child.snapshot.data['icon'],
      };

      breadcrumbs.push(breadcrumb); // Añadir el breadcrumb actual

      // Verificar si hay más hijos y continuar construyendo los breadcrumbs
      const moreBreadcrumbs = this.createBreadcrumbs(child, fullURL, []);
      breadcrumbs = [...breadcrumbs, ...moreBreadcrumbs];
    }

    return breadcrumbs;
  }
}
