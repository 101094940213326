import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { FilterConfig } from '@core/interfaces/filters.interface';
import { ProgramsFilter } from '@core/interfaces/Programs.interface';
import { FilterService } from '@core/services/filters/filter.service';
import { Subject, Subscription, debounceTime } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search-input.component.html',
  styleUrls: [],
})
export class SearchInputComponent implements OnInit, OnDestroy {
  private debouncer: Subject<any> = new Subject<any>();
  private debouncerSubscription?: Subscription;

  @Output() filterEvent = new EventEmitter<any>();

  filtersForm: FormGroup;

  filterConfigs: FilterConfig[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private filterService: FilterService
  ) {
    this.filtersForm = this.formBuilder.group({});
    this.initializeFilters();
  }

  ngOnInit(): void {
    this.filterService.filterConfig$.subscribe((filters) => {
      this.filterConfigs = filters;
      this.initializeFilters();
    });

    this.debouncerSubscription = this.debouncer
      .pipe(debounceTime(500))
      .subscribe((event) => {
        const textFilter = event.target.value;
        // Emite el evento de filtro
        this.filterEvent.emit(event);
        // Aplica los filtros con el valor de búsqueda
        this.applyFilters(textFilter);
      });
  }

  /**
   * Inicializa los controles del formulario de filtros basado en la configuración recibida
   */
  initializeFilters() {
    this.filtersForm = this.formBuilder.group({});
    this.filterConfigs.forEach((config) => {
      this.filtersForm.addControl(config.name, new FormControl(null));
    });

    this.filtersForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });
  }

  /**
   * Aplica los filtros y los envía al servicio de filtros
   * @param {string} [searchValue] - Valor de búsqueda parcial
   */
  applyFilters(searchValue?: string) {
    // 1. Obtener los valores de los filtros del formulario
    const sendFilters: any = {};
    const filters: ProgramsFilter = this.filtersForm.value;
    // Si hay un valor de búsqueda, agregarlo a los filtros a enviar
    if (searchValue) {
      sendFilters['searchValue'] = searchValue;
    }

    // 2. Filtrar los valores nulos y agregar los filtros configurados
    for (const filterIn of this.filterConfigs) {
      if (filters[filterIn.name]) {
        sendFilters[filterIn.dbName] = filters[filterIn.name];
      }
    }
    // Establece los filtros en el servicio de filtros
    this.filterService.setFilters(sendFilters);
  }

  /**
   * Envía el valor de búsqueda al debouncer
   */
  sendSearch(event: any) {
    this.debouncer.next(event);
  }

  ngOnDestroy(): void {
    this.debouncerSubscription?.unsubscribe();
  }
}
