<div
  class="h-100vh pt-50 pb-50"
  [class.component-dark-theme]="themeService.isDark()"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.card-border-radius]="themeService.isCardBorderRadius()"
>
  <div class="d-table">
    <div class="d-table-cell">
      <div
        class="logout-box text-center bg-white border-radius ms-auto me-auto"
      >
        <div class="logo">
          <img [src]="img" class="mb-25" alt="img" />
        </div>

        <h5 class="fw-semibold mb-12">{{ title }}</h5>
        <p>{{ msg }}</p>
        <a
          mat-flat-button
          class="tagus d-block fw-semibold"
          *ngIf="activateButton"
          [routerLink]="['/reset-password']"
          [queryParams]="{ token: tokenReset }"
          routerLinkActive="router-link-active"
        >
          Continuar
        </a>
      </div>
    </div>
  </div>
</div>
