<mat-card
  class="mb-25 file-manager-box tagus-card"
  [class.component-dark-theme]="themeService.isDark()"
  [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
  <mat-card-header>
    <h5 class="mb-0">Multimedias</h5>
  </mat-card-header>
  <mat-card-content>
    <form class="search-box position-relative">
      <input
        type="text"
        class="input-search d-block"
        placeholder="Search here.."
      />
      <button type="submit"><i class="ri-search-line"></i></button>
    </form>
    <ul class="file-manager-sidebar ps-0 mb-0">
      <li *ngFor="let folder of folders">
        <a
          class="fw-semibold position-relative"
          style="cursor: pointer"
          (click)="selectFolder(folder)"
        >
          <i class="ri-file-text-line"></i>
          {{ folder }}
        </a>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
