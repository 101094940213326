<mat-card class="profile-card mb-25 tagus-card">
  <mat-card-header class="border-bottom">
    <h5 class="mb-0 tertiary-blue-color">Proyectos</h5>
  </mat-card-header>
  <mat-card-content>
    <button mat-flat-button class="gray" (click)="openDialogProject()">
      Agregar
    </button>
    <br />
    <mat-divider></mat-divider>
    <div
      class="row"
      [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    >
      <div
        class="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3"
        *ngFor="let project of projects"
      >
        <mat-card class="mb-25 text-center tagus-card">
          <mat-card-content>
            <img
              src="{{ project.logo }}"
              class="rounded-circle mb-15"
              width="150"
              alt="user"
            />
            <h5 class="mb-5 fw-semibold">{{ project.name }}</h5>
            <span class="d-block muted-color">{{ project.name }}</span>
            <div class="btn-box">
              <button
                mat-flat-button
                class="tagus"
                (click)="openDialogProject(project)"
              >
                Editar
              </button>
              <button mat-flat-button class="gray">Eliminar</button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>
