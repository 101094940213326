import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { MultimediasService } from '@core/services/programs/multimedias.service';
import { FilterService } from '@core/services/filters/filter.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';
import { ProgramsService } from '@core/services/programs/programs.service';
import { Multimedias } from '@core/interfaces/multimedia.interface';
import { Multimedia } from '@core/interfaces/Programs.interface';

@Component({
  selector: 'app-dialog-media',
  templateUrl: './dialog-media.component.html',
  styleUrls: ['./dialog-media.component.scss'],
})
export class DialogMediaComponent implements OnInit, OnDestroy {
  public archivos: File | null = null;
  public limit: number = 50;
  public offset: number = 1;
  public totalItems?: number;
  displayedColumns: string[] = ['name', 'url', 'type', 'action'];
  public listMultimedias: Multimedia[] = [];
  dataSource = new MatTableDataSource<Multimedia>(this.listMultimedias);
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  private filterSubscription: Subscription | undefined;
  public files: File[] = [];
  public searchValue: string = '';
  public multimediaCtrl = new FormControl();

  public folder: string = '';
  public listSelect: any[] = [
    { value: 'enlace' },
    { value: 'multimedia' },
    { value: 'cargar' },
  ];
  public step!: FormGroup;
  public fileData: { name: string; base: any } = { name: '', base: '' };
  public idMultimedia: string = '';

  constructor(
    private fb: FormBuilder,
    private _mediasService: MultimediasService,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogMediaComponent>,
    private sweetAlertService: SweetAlertService,
    private _programService: ProgramsService
  ) {}

  ngOnInit(): void {
    this.getListMultimedias();

    this.step = this.fb.group({
      numberStep: [''],
      interaction: ['Informativo', Validators.required],
      multimedia: [''],
      description: [''],
    });

    if (this.data && this.data.step !== undefined) {
      this.step.patchValue({
        numberStep: this.data.step.numberStep,
        interaction: this.data.step.interaction,
        description: this.data.step.description,
        multimedia: this.data.step.multimedia,
      });
    }

    this.step.get('files')?.valueChanges.subscribe((files) => {
      if (files && files.length > 0) {
        this.step.get('fileUrl')?.disable();
      } else {
        this.step.get('fileUrl')?.enable();
      }
    });

    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe(
      (filters) => {
        this.searchValue = filters.searchValue;
        // Obtener la lista de programas paginada con los filtros aplicados
        this.getListMultimedias(this.searchValue);
      }
    );
  }

  ngOnDestroy(): void {
    this.step.reset();
    this.multimediaCtrl.reset();
    this.listMultimedias = [];
    this.files = [];
    // Destruir la suscripción
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  getListMultimedias(searchValue: string = '') {
    if (searchValue) {
      this.limit = 1;
    }
    this._mediasService
      .getListMedia(this.limit, this.offset, searchValue)
      .subscribe(
        (resp: Multimedias) => {
          if (Array.isArray(resp.data)) {
            this.listMultimedias = resp.data;
            this.totalItems = resp.total;
            this.dataSource.data = this.listMultimedias;
            this.dataSource = new MatTableDataSource(this.listMultimedias);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  editStepById(idProgram: string) {
    this._programService.updateStep(idProgram, this.step.value).subscribe(
      (resp: any) => {
        if (resp.ok) {
          this.handleMediaUpload();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async eventStep() {
    if (this.data && this.data.step !== undefined) {
      await this.editStepById(this.data.idProgram);
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.step.value);
    }
  }

  registerMedia() {
    let newMedia = {
      name: this.step.value.title,
      fileUrl: this.step.value.fileUrl,
    };

    this._mediasService.guardarLinkVideo(newMedia).subscribe(
      (res: any) => {
        this.handleMediaUpload();
        this.step.value.media = res;
        this.step.value.fileUrl = '';
        this.step.value.title = '';
      },
      (err) => {
        console.error('Error al registrar la media:', err);
        let errorMessage = 'Se ha presentado un error al registrar la media';
        if (err && err.error && err.error.message) {
          errorMessage = err.error.message;
        }
        Swal.fire('Registro de medios', errorMessage, 'error');
      }
    );
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.limit = event.pageIndex + 1;
    this.offset = event.offset;
    this.pageChange.emit({
      pageIndex: this.limit,
      offset: this.offset,
    });
    this.getListMultimedias(this.searchValue);
  }

  cargarArchivo() {
    const formData = new FormData();
    formData.append('carpeta', this.step.value.title || this.archivos?.name);
    if (this.archivos instanceof File) {
      formData.append('file', this.archivos);
    }

    Swal.fire({
      title: 'Subiendo archivo...',
      text: 'Por favor, espere unos segundos',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();

        this._mediasService.cargarMedia(formData).subscribe(
          (res: Multimedias) => {
            if (res.ok) {
              if (!Array.isArray(res.data)) {
                this.step.value.multimedia = {
                  url: res.data.url,
                  type: res.data.type,
                };
                this.sweetAlertService.showSuccessAlert(
                  '¡Éxito!',
                  'Se ha cargado el archivo correctamente'
                );
              }
            }
          },
          (err) => {
            console.error('Error al cargar la media:', err);
            let errorMessage = 'Se ha presentado un error al cargar la media';
            if (err && err.error && err.error.message) {
              errorMessage = err.error.message;
            }
            Swal.fire('Carga de medios', errorMessage, 'error');
          }
        );
      },
    });
  }

  selectMedia(event: any) {
    this.step.value.multimedia = {
      url: event.value.url,
      type: event.value.type,
    };
  }

  onSelect(event: any) {
    if (event.addedFiles.length > 0) {
      const file = event.addedFiles[0];
      this.files.push(file);
      this.archivos = file;
      this.step.get('files')?.setValue(this.files);
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    this.step.get('files')?.setValue(this.files);
    if (this.files.length === 0) {
      this.step.get('fileUrl')?.enable();
    }
  }

  checkFileUrl(event: any) {
    this.step.patchValue({
      multimedia: {
        url: event.data,
        type: 'enlace',
      },
    });

    if (this.step.get('fileUrl')?.value) {
      this.files = [];
    }
  }

  obtenerCarpetaPorFormato(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension) {
      if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        return 'img';
      } else if (['doc', 'docx', 'xlsx', 'pdf'].includes(extension)) {
        return 'documents';
      } else if (['mp3'].includes(extension)) {
        return 'audio';
      }
    }
    return 'otros';
  }

  saveMedia() {
    if (this.files.length > 0) {
      this.cargarArchivo();
    } else if (this.step.get('fileUrl')?.value) {
      this.registerMedia();
    }
  }

  async handleMediaUpload(): Promise<void> {
    Swal.fire({
      icon: 'success',
      title: '<strong>¡Éxito!</strong>',
      text: 'Se ha añadido la multimedia al paso correctamente',
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      color: '#1d2c4d',
      confirmButtonColor: '#262D48',
      background: '#ffffff',
      width: '28rem',
    });
  }

  close() {
    this.dialogRef.close();
  }
}
