import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from '@core/interfaces/auth.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-confirm-mail',
  templateUrl: './confirm-mail.component.html',
  styleUrls: ['./confirm-mail.component.scss'],
})
export class ConfirmMailComponent implements OnInit {
  queryParams: any;
  activateButton: boolean = false;
  img = 'assets/img/events/error.gif';
  tokenReset: string = '';
  activeSeccion: boolean = false;
  msg =
    '😔 Algo salió mal. Por favor, verifica que el enlace sea correcto o que no haya expirado🕒.  Comunicate Con el administrador del sistema 🖥️';
  title = '¡Correo verificado! 📧';

  constructor(
    public themeService: CustomizerSettingsService,
    private route: ActivatedRoute,
    private _authService: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
      Swal.fire({
        title: 'Espera!!!',
        text: 'Estamos verificando tu correo, por favor espera un momento...',
        imageUrl: '../../../../assets/img/waiting.gif',
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
      });
    });

    this.verifyEmail();
  }

  verifyEmail() {
    if (this.queryParams.token) {
      this._authService.validateTokenEmail(this.queryParams.token).subscribe(
        (res: any) => {
          if (res.ok) {
            this.tokenReset = res.data || '';
            this.img = 'assets/img/success.gif';
            this.activateButton = true;
            this.activeSeccion = true;
            // SweetAlert para mostrar confirmación
            Swal.fire({
              icon: 'success',
              title: 'Correo verificado',
              text: 'Tu correo ha sido verificado correctamente.',
            });
          }
        },
        (err) => {
          this.activeSeccion = true;
          this.title = '¡Correo no verificado! 📧';
          this.img = 'assets/img/events/error.gif';
          this.msg =
            '😔 Algo salió mal. Por favor, verifica que el enlace sea correcto o que no haya expirado🕒.  Comunicate Con el administrador del sistema 🖥️';
          // SweetAlert para mostrar error
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al verificar tu correo.',
          });
        }
      );
    }
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }
}
