<div class="not-found text-center">
  <img src="assets/img/not-found.gif" class="mb-25" alt="not-found" />
  <h4 class="mb-15">¡Oops! La página que estás buscando no existe.</h4>
  <p class="ms-auto me-auto">
    La página que estás buscando podría haber sido eliminada, haber cambiado de
    nombre o estar temporalmente no disponible.
  </p>
  <a
    mat-raised-button
    class="tagus mt-8 bg-main-color-orange-button"
    [routerLink]="['/dashboard']"
  >
    Volver al Dashboard
  </a>
</div>
