import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  Observable,
  catchError,
  retry,
  share,
  shareReplay,
  throwError,
} from 'rxjs';
import { AuthStateService } from '@core/states/auth-state.service';
import { Project, Projects } from '@core/interfaces/project.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  public urlBackend: string = environment.base_url;
  public urlProject: string = 'project';
  public headers: any;
  public tokenUser: string = '';
  public idUser: string = '';
  private projects$: Observable<Projects> | undefined;
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('user', `${this.idUser}`);
  }

  getProjectsList(): Observable<Projects> {
    if (!this.projects$) {
      const headers = this.getHeaders();
      this.projects$ = this.http
        .get<Projects>(`${this.urlBackend}/${this.urlProject}`, {
          headers: headers,
        })
        .pipe(
          retry(1),
          shareReplay(1),
          catchError((error: HttpErrorResponse) => {
            let errorMessage =
              'Ocurrió un error en la solicitud de la lista de proyectos';
            if (error.error instanceof ErrorEvent) {
              // Error del cliente, como red no disponible
              console.error('Error del cliente:', error.error.message);
            } else {
              // El servidor devolvió un código de error
              console.error(
                `Error en la solicitud de getProjectsList: ${error.status}, ` +
                  `Mensaje de error: ${error.error.msg}`
              );
              errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
            }
            // Devuelve el mensaje de error al componente
            return throwError(error);
          })
        );
    }
    return this.projects$; // Devuelve el observable compartido
  }

  getProjectById(id: string): Observable<Project> {
    const headers = this.getHeaders();
    return this.http
      .get<Project>(`${this.urlBackend}/${this.urlProject}/${id}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las getProjectById';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getProjectById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  registerProject(data: Project): Observable<Project> {
    const headers = this.getHeaders();
    return this.http
      .post<Project>(`${this.urlBackend}/${this.urlProject}`, data, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las registerProject';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de registerProject: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  updateProject(data: Project, id: string): Observable<Project> {
    const headers = this.getHeaders();

    return this.http
      .put<Project>(`${this.urlBackend}/${this.urlProject}/${id}`, data, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las updateProject';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de updateProject: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  deleteProject(id: string): Observable<Project> {
    const headers = this.getHeaders();
    return this.http
      .delete<Project>(`${this.urlBackend}/${this.urlProject}/${id}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las deleteProject';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de deleteProject: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
