<app-card
  title="Listado de líneas"
  [link]="'/lines/new-line'"
  [titleRouter]="titleButton"
>
  <app-table-data
    [data]="listLine"
    [dataSource]="dataSource"
    [columns]="displayedColumns"
    [pageSizeOptions]="[10, 20, 50, 100, 200]"
    [totalItems]="totalItems"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [editButton]="true"
    [codeqrButton]="true"
    [deleteButton]="true"
    [viewButton]="true"
    (editEvent)="editLine($event)"
    (codeqrEvent)="camapaingLine($event)"
    (deleteEvent)="deleteLine($event)"
    (pageChange)="onPageChange($event)"
  ></app-table-data>
</app-card>
