<app-dialog
  [title]="'Proyectos'"
  (saveEvent)="eventData()"
  (closeEvent)="closeEvent()"
>
  <form [formGroup]="projectForm">
    <div class="row">
      <div class="tagus-form-group">
        <div class="col-lg-12 col-md-12">
          <label class="d-block mb-12 fw-semibold subtitle-card-color">
            Nombre
          </label>
          <mat-form-field appearance="fill">
            <i class="ri-file-text-line"></i>
            <mat-label>Escribe el nombre</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>
      </div>

      <div class="tagus-form-group" *ngIf="urlImg">
        <div class="col-lg-12 col-md-12">
          <img src="{{ urlImg }}" class="center" width="250" />
        </div>
      </div>
      <hr />
      <div class="tagus-form-group">
        <div class="col-lg-12 col-md-12">
          <ngx-dropzone
            (change)="onSelect($event)"
            class="dropzone"
            [accept]="'image/*'"
          >
            <ngx-dropzone-label>
              Arrastra y suelta imágenes o haz clic aquí para seleccionarlas.
            </ngx-dropzone-label>
            <ngx-dropzone-image-preview
              *ngFor="let f of files"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f)"
            >
              <ngx-dropzone-label>
                {{ f.name }} ({{ f.type }})
              </ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </div>
      </div>
    </div>
  </form>
</app-dialog>
