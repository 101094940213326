import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { SearchLevelByPosition } from '@core/interfaces/searchLevel.interface';
import { FindNode } from '@core/interfaces/findNode.interface';
@Injectable({
  providedIn: 'root',
})
export class MatchService {
  public url: string = environment.base_url;
  public userData: any;
  public headers: any;
  public tokenUser: string = '';
  public matchUrl: string = 'match-programs';
  constructor(private http: HttpClient) {
    this.headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token_conhector') || ''}`,
      'idUser': localStorage.getItem('user_conhector') || ' ',
    });
  }

  private handleError(error: any): Observable<any> {
    console.error('An error occurred', error);
    return throwError(error);
  }

  getListMatchByIdLine(id: string): Observable<any> {
    const params = new HttpParams().set('line', id);
    return this.http
      .get(`${this.url}/${this.matchUrl}/line`, {
        params: params,
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  registerNewLevel(id: string, data: any): Observable<any> {
    const params = new HttpParams().set('matchId', id);

    return this.http
      .post(`${this.url}/${this.matchUrl}/addLevel`, data, {
        params: params,
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  updateProgram(idMatch: string, data: any): Observable<any> {
    const params = new HttpParams().set('idMatch', idMatch);

    return this.http
      .patch(`${this.url}/${this.matchUrl}/updateLevel`, data, {
        params: params,
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  addLevelFrist(idMatch: string, data: any): Observable<any> {
    const params = new HttpParams().set('matchId', idMatch);
    return this.http
      .post(`${this.url}/${this.matchUrl}/addLevel`, data, {
        params: params,
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }

  deleteLevel(
    idMatch: string,
    level: string,
    position: string,
    dataDelete: any
  ): Observable<any> {
    const params = new HttpParams().set('id', idMatch);
    return this.http
      .delete(`${this.url}/${this.matchUrl}/deleteLevel`, {
        params: params,
        headers: this.headers,
        body: dataDelete,
      })
      .pipe(catchError(this.handleError));
  }

  /** Agrega un nuevo nivel al Match por ID */
  addLevelMatchById(id: string, data: any): Observable<any> {
    return this.http
      .post(`${this.url}/match-programs/addlevel`, data, {
        params: { matchId: id },
      })
      .pipe(catchError(this.handleError));
  }

  /** Actualiza un nivel del Match por ID */
  updateMatchProgramById(id: string, data: any): Observable<any> {
    return this.http
      .patch(`${this.url}/match-programs/updateLevel`, data, {
        params: { idMatch: id },
      })
      .pipe(catchError(this.handleError));
  }

  /** Elimina un nivel del Match por ID */
  deleteLevelMatchById(id: string, data: any): Observable<any> {
    return this.http
      .delete(`${this.url}/match-programs/deletelevel`, {
        body: data,
        params: {
          id: id,
        },
      })
      .pipe(catchError(this.handleError));
  }

  /** Busca un nodo y su padre dentro de un Match */
  findLevelAndParent(data: any): Observable<any> {
    return this.http
      .post(`${this.url}/find-node-and-parent`, data)
      .pipe(catchError(this.handleError));
  }

  /** Obtiene un Match por su ID */
  getMatchById(id: string): Observable<any> {
    return this.http
      .get(`${this.url}/match-programs`)
      .pipe(catchError(this.handleError));
  }

  searchNodeParents(data: any): Observable<FindNode> {
    return this.http
      .post<FindNode>(`${this.url}/${this.matchUrl}/find-node`, data, {
        headers: this.headers,
      })
      .pipe(catchError(this.handleError));
  }
}
