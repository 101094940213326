import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Multimedia,
  Multimedias,
} from '@core/interfaces/multimiedias.interface';
import { Project } from '@core/interfaces/project.interface';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { MultimediasService } from '@core/services/multimedias.service';

@Component({
  selector: 'app-project-modal',
  templateUrl: './project-modal.component.html',
  styleUrls: ['./project-modal.component.scss'],
})
export class ProjectModalComponent {
  public files: File[] = [];

  public archivos: File | null = null;

  public urlImg = '';
  public projectForm = this.fb.group({
    img: ['', Validators.required],
    name: ['', Validators.required],
  });

  constructor(
    public fb: FormBuilder,
    public _projectService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProjectModalComponent>,
    private _mediasService: MultimediasService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      const project = this.data.project;
      this.projectForm.get('name')?.setValue(project.name);
      this.urlImg = project.logo;
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onSelect(event: any) {
    if (event.addedFiles.length > 0) {
      const file = event.addedFiles[0];
      this.files.push(file);
      this.archivos = file;
    }
  }

  saveImg() {
    const name = this.projectForm.get('name')?.value + '_folder';
    if (!this.archivos) {
      console.error('No file selected');
      return;
    }
    const file = this.archivos;
    this._mediasService.addMultimedia('Projects', name, file).subscribe(
      (res: Multimedias) => {
        if (res.ok) {
          if (!(res.data instanceof Array)) {
            this.registerNewProject(res.data);
          }
        }
      },
      (err: any) => {
        console.error('Error uploading media:', err);
        let errorMessage = 'An error occurred while uploading the media';
        if (err && err.error && err.error.message) {
          errorMessage = err.error.message;
        }
      }
    );
  }

  registerNewProject(data?: Multimedia) {
    const newProject = {
      name: this.projectForm.get('name')?.value || '',
      logo: data!.mediaLink,
    };
    this._projectService.registerProject(newProject).subscribe(
      (res: any) => {
        if (res.ok) {
          this.dialogRef.close({
            reload: true,
          });
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  eventData() {
    this.saveImg();
  }

  closeEvent() {
    this.dialogRef.close({
      reload: false,
    });
  }
}
