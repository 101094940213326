<mat-form-field class="example-full-width">
  <i class="ri-flag-line"></i>
  <mat-label>{{ title }}</mat-label>
  <input
    type="text"
    placeholder="{{ placeholder }}"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    [value]="valueData"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option"
      (click)="onSelectOption(option)"
    >
      <ng-container *ngFor="let prop of displayProperties; let i = index">
        <ng-container [ngSwitch]="propertyClass[i]">
          <img
            *ngSwitchCase="'img'"
            [src]="option[prop]"
            alt="Image"
            style="width: 20px; height: 20px; margin-right: 8px"
          />
          <span *ngSwitchCase="'text'">{{ option[prop] }}</span>
          <mat-checkbox *ngSwitchCase="'checkbox'" [checked]="option[prop]">
            {{ option[prop] }}
          </mat-checkbox>
          <span *ngSwitchDefault>{{ option[prop] }}</span>
        </ng-container>
        <span *ngIf="!last(i)"></span>
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
