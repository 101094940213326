import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth, Login } from '@core/interfaces/auth.interface';
import { User, Users } from '@core/interfaces/User.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import { UsersService } from '@core/services/configurations/users.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import Swal from 'sweetalert2';
import { ResponsePublicKey } from '../interfaces/response-publicKey.interface';
import { AuthStateService } from '@core/states/auth-state.service';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
})
export class LockScreenComponent implements OnInit {
  hide = true;

  saludoImg =
    '../../../../assets/img/ConHector/Personaje_acciones/ConHector_1.png';
  errorImg =
    '../../../../assets/img/ConHector/Personaje_acciones/ConHector_Dudoso.png';
  internalErrorImg = '../../../../assets/img/error_icons/error_500.gif';
  lockedUserImg = '../../../../assets/img/error_icons/locked.gif';

  public idUser: string = '';
  public userInfo: User = {};
  public publicKey: string = '';
  public emailUser: string = '';
  public loginForm = this.fb.group({
    email: [this.userInfo.email || ''],
    password: ['', Validators.required],
  });
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public _authService: AuthService,
    private authStateService: AuthStateService,
    public _userService: UsersService,
    public themeService: CustomizerSettingsService
  ) {
    this.idUser = localStorage.getItem('user_conhector') || '';
  }

  ngOnInit(): void {
    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });
    this.getPublicKey();

    this.authStateService.emailUser$.subscribe((email) => {
      this.emailUser = email || '';
    });
  }

  async login() {
    const encryptedPassword = await this._authService.encryptData(
      this.loginForm.value.password || '',
      this.publicKey
    );
    const loginData: Login = {
      email: this.emailUser || '',
      password: encryptedPassword,
    };

    this._authService.loginService(loginData).subscribe(
      (resp: Auth) => {
        if (resp.ok) {
          if (resp.ok) {
            this.authStateService.setUserId(resp.data?._id || null);
            this.authStateService.setToken(resp.data?.token || null);
            this.authStateService.setNameUser(resp.data?.name || null);
            this.authStateService.setLastNameUser(resp.data?.last_name || null);
            this.authStateService.setEmailUser(resp.data?.email || null);
            this.authStateService.setImgUser(resp.data?.img || null);
            this.authStateService.setProfileUser(
              resp.data?.profile ? [resp.data.profile] : null
            );

            this.router.navigate(['/']);
          }
        }
      },
      (error) => {
        if (error.status === 500) {
          Swal.fire({
            title: 'Upps!!',
            text: 'Se ha presentado un error interno en el servidor, por favor intente más tarde. O comunicate con Sistemas',
            imageUrl: this.internalErrorImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        } else if (error.status === 401) {
          Swal.fire({
            title: 'Upps!!',
            text: 'Tu cuenta ha sido bloqueada por seguridad, a tu correo se ha enviado un mensaje para desbloquearla',
            imageUrl: this.lockedUserImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        } else {
          Swal.fire({
            title: 'Upps!!',
            text:
              error.error.msg ||
              'Ocurrió un error en la solicitud de inicio de sesión',
            imageUrl: this.errorImg,
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: 'Custom image',
          });
        }
      }
    );
  }
  getPublicKey() {
    this._authService.getPublicKey().subscribe(
      (resp: ResponsePublicKey) => {
        this.publicKey = resp.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
