import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Step, Steps } from '@core/interfaces/Steps.interface';
import { Program } from '@core/interfaces/Programs.interface';

@Component({
  selector: 'app-dialog-view-program',
  templateUrl: './dialog-view-program.component.html',
  styleUrls: ['./dialog-media.component.scss'],
})
export class DialogViewProgramComponent {
  public title: string;
  programs: FormGroup;
  public dataProgram: Program[] = [];
  constructor(
    public dialogRef: MatDialogRef<DialogViewProgramComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.programs = this.fb.group({
      program_name: [data.program.program_name],
      type: [data.program.type],
      coverage: [data.program.coverage],
      Subcategory: [data.program.Subcategory],
      start_date: [data.program.start_date],
      end_date: [data.program.end_date],
      menu_option: [data.program.menu_option],
    });
    this.title = data.program.program_name;
    this.dataProgram = data.program.steps;
  }

  OnInit() {}

  close() {
    this.dialogRef.close();
  }
}
