import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService {
  public urlBackEnd: string = environment.base_url;
  public headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${localStorage.getItem('token_conhector')}`)
      .set('iduser', `${localStorage.getItem('user_conhector')}`);
  }

  checkGroupExists(groupId: string): Observable<any> {
    const params = new HttpParams().set('groupId', groupId);
    return this.http
      .get<any>(`${this.urlBackEnd}/campaigns/check-group`, {
        headers: this.headers,
        params,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al verificar el grupo';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de checkGroupExists: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  getCampaignByGroup(groupId: string): Observable<any> {
    const params = new HttpParams().set('groupId', groupId);
    return this.http
      .get<any>(`${this.urlBackEnd}/campaigns/group`, {
        headers: this.headers,
        params,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al obtener la campaña';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getCampaignByGroup: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  createCampaign(data: any): Observable<any> {
    return this.http
      .post<any>(`${this.urlBackEnd}/campaigns`, data, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al crear la campaña';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de createCampaign: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  updateCampaign(id: string, data: any): Observable<any> {
    return this.http
      .put<any>(`${this.urlBackEnd}/campaigns/${id}`, data, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al actualizar la campaña';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de updateCampaign: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  deleteCampaign(id: string): Observable<any> {
    return this.http
      .delete<any>(`${this.urlBackEnd}/campaigns/${id}`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al eliminar la campaña';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de deleteCampaign: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  redirectCampaign(code: string): Observable<any> {
    const params = new HttpParams().set('code', code);
    return this.http
      .get<any>(`${this.urlBackEnd}/campaigns/redirect/${code}`, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al verificar el grupo';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de checkGroupExists: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  generateUniqueCode(groupId: string): Observable<any> {
    const data = { groupId: groupId };
    return this.http
      .post<any>(`${this.urlBackEnd}/campaigns/generate-code`, data, {
        headers: this.headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error al verificar el grupo';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de checkGroupExists: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }
}
