import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FilterConfig } from '@core/interfaces/filters.interface';
import {
  Participant,
  ParticipantsFilter,
  ParticipantsPaginate,
} from '@core/interfaces/participants.interface';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { ParticipantsService } from '@core/services/participants/participants.service';
import { FilterService } from '@core/services/filters/filter.service';
import { Subscription } from 'rxjs';
import { SweetAlertService } from './../../../../core/services/sweet-alert.service';

/**
 * Componente para listar participantes con paginación y filtros.
 */
@Component({
  selector: 'app-list-participants',
  templateUrl: './list-participants.component.html',
  styleUrls: ['./list-participants.component.scss'],
})
export class ListParticipantsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /** Número de página actual. */
  public pageIndex: number = 1;
  /** Tamaño de la página actual. */
  public pageSize: number = 50;
  /** Número total de elementos. */
  public totalItems?: number;
  /** Columnas a mostrar en la tabla. */
  displayedColumns: string[] = [
    'name',
    'phone',
    'genero',
    'line',
    'group',
    'type',
    'action',
  ];

  /** Evento de cambio de página. */
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();

  public filters: any = {};

  public dataFilters: ParticipantsFilter = {};

  public listParticipants: Participant[] = [];

  public cachedData: { [key: number]: Participant[] } = {};

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  filterConfigs: FilterConfig[] = [];
  private filterSubscription: Subscription | undefined;

  dataSource = new MatTableDataSource<Participant>(this.listParticipants);

  constructor(
    public dialog: MatDialog,
    public participantsService: ParticipantsService,
    private filterService: FilterService,
    private sweetAlertService: SweetAlertService
  ) {}

  /**
   * Método de inicialización del componente.
   */
  ngOnInit(): void {
    // Obtener configuración de filtros al iniciar
    this.participantsService
      .getListFiltersParticipants()
      .subscribe((resp: any) => {
        const lines: string[] = Object.keys(resp.filters);

        // Configuración de los filtros
        this.filterConfigs = [
          {
            name: 'Linea',
            icon: 'ri-list-check-2',
            options: lines,
            dbName: 'line',
          },
          {
            name: 'Grupo',
            icon: 'ri-bar-chart-horizontal-fill',
            options: [],
            dbName: 'group',
          },
          {
            name: 'Tipo de participante',
            icon: 'ri-bard-line',
            options: [],
            dbName: 'type',
          },
          {
            name: 'Categoría',
            icon: 'ri-bard-line',
            options: [],
            dbName: 'category',
          },
        ];
        this.filters = resp.filters;
        // Pasar la configuración de filtros al servicio
        this.filterService.setFilterConfigs(this.filterConfigs, resp.filters);
      });

    // Inicializar la suscripción al subject del servicio de filtrado
    this.filterSubscription = this.filterService.filter$.subscribe(
      (filters) => {
        if (!filters.line) {
          this.dataFilters = {};
          this.filterService.setFilterConfigs(this.filterConfigs, this.filters);
          this.getListParticipantsPaginate();
        }

        const selectedCategoryData = this.filterService.getFilterDataByCategory(
          filters.line
        );
        if (filters.line) {
          if (selectedCategoryData) {
            this.filterConfigs.find(
              (config) => config.name === 'Grupo'
            )!.options = selectedCategoryData?.group.map(
              (group: any) => group?.name
            );
            this.filterConfigs.find(
              (config) => config.name === 'Tipo de participante'
            )!.options = selectedCategoryData?.type.map(
              (type: any) => type?.name
            );
            this.filterConfigs.find(
              (config) => config.name === 'Categoría'
            )!.options = selectedCategoryData?.category.map(
              (type: any) => type?.name
            );
          }
        }
        for (const itemFilter in filters) {
          if (itemFilter !== 'searchValue') {
            if (selectedCategoryData[itemFilter]) {
              const value = selectedCategoryData[itemFilter]?.find(
                (value: any) => value.name === filters[itemFilter]
              );
              if (value) {
                filters[itemFilter] = value.id;
              } else {
                delete filters[itemFilter];
              }
            }
          }
        }
        this.getListParticipantsPaginate(filters);
        this.dataFilters = filters;
      }
    );

    // Obtener la lista inicial de participantes paginada
    this.getListParticipantsPaginate();
  }

  /**
   * Método que se ejecuta después de la inicialización de la vista.
   */
  ngAfterViewInit(): void {
    if (this.paginator) {
      // Manejar la paginación de la tabla
      this.paginator.page.subscribe((event: PageEvent) => {
        this.pageIndex = event.pageIndex + 1;
        this.pageSize = event.pageSize;

        // Verificar si los datos ya están en caché antes de hacer la consulta
        if (
          this.cachedData[this.pageIndex] &&
          this.cachedData[this.pageIndex].length === this.pageSize
        ) {
          this.listParticipants = this.cachedData[this.pageIndex];
          this.dataSource.data = this.listParticipants;
        } else {
          this.getListParticipantsPaginate();
        }
      });
    }
  }

  /**
   * Método de destrucción del componente.
   */
  ngOnDestroy(): void {
    // Destruir la suscripción
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
    this.cachedData = {};
  }

  /**
   * Obtiene la lista de participantes con paginación.
   * @param filters Filtros aplicados a la consulta.
   */
  getListParticipantsPaginate(filters?: any) {
    this.participantsService
      .getListParticipantsPaginate(this.pageIndex, this.pageSize, filters)
      .subscribe(
        (resp: ParticipantsPaginate) => {
          if (resp.ok) {
            this.listParticipants = resp.participants.data;
            this.totalItems = resp.participants.pagination.total;
            this.dataSource = new MatTableDataSource(this.listParticipants);

            // Actualizar los datos en caché con el nuevo tamaño de la página
            this.cachedData[this.pageIndex] = this.listParticipants;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  deleteParticipant(event: any) {
    this.sweetAlertService
      .showConfirmActionAlert(
        'Eliminar participante',
        '¿Estás seguro de eliminar este participante?',
        'El participante ha sido eliminado correctamente.',
        'Eliminar'
      )
      .then((result) => {
        if (result.isConfirmed) {
          this.participantsService
            .deleteParticipant(event.phone)
            .subscribe((resp: any) => {
              if (resp.ok) {
                this.getListParticipantsPaginate();
              }
            });
        }
      });

    this.participantsService
      .deleteParticipant(event.phone)
      .subscribe((resp: any) => {});
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    this.getListParticipantsPaginate(this.dataFilters);
  }
}
