<app-breadcrumbs *ngIf="breadCrumb"></app-breadcrumbs>

<mat-card
  class="mb-25 tagus-card enhanced-card"
  [class.component-dark-theme]="themeService.isDark()"
  [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
  <mat-card-header class="enhanced-card-header">
    <h5 class="mb-0 title-color">{{ title }}</h5>
    <br />
    <br />
    <br />
    <ng-container *ngIf="link">
      <a
        mat-flat-button
        class="default-btn bg-color-blue-button"
        [routerLink]="link"
        routerLinkActive="active"
      >
        <i class="ri-add-fill"></i>
        {{ titleRouter }}
      </a>
    </ng-container>

    <ng-container *ngIf="titleButton">
      <button
        mat-flat-button
        class="default-btn bg-color-blue-button"
        (click)="actionEvent()"
        routerLinkActive="active"
      >
        <i class="ri-add-fill"></i>
        {{ titleButton }}
      </button>
    </ng-container>
  </mat-card-header>

  <mat-card-content>
    <div class="teamMembersListTable tagus-mat-table mat-elevation-z8">
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>
