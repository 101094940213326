import { Component, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-dialog-view-campaign',
  templateUrl: './dialog-view-campaign.component.html',
  styleUrls: ['./dialog-view-campaign.component.scss'],
})
export class DialogViewCampaignComponent {
  sentMessage: string;
  questions: string[];
  constructor(
    public dialogRef: MatDialogRef<DialogViewCampaignComponent>,
    @Inject(MAT_DIALOG_DATA) public campaign: any,
    private clipboard: Clipboard
  ) {
    this.sentMessage = this.campaign.prefilled_message;
    this.questions = this.campaign.registry;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.campaign.deep_link_url);
  }

  onEdit(): void {
    this.dialogRef.close(true);
  }
}
