import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Projects, Project } from '@core/interfaces/project.interface';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';
import { ProjectModalComponent } from '../dialog/project-modal/project-modal.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent {
  public projects: Project[] = [];

  constructor(
    public dialog: MatDialog,
    private projectsService: ProjectsService,
    public themeService: CustomizerSettingsService
  ) {}

  ngOnInit(): void {
    this.getListProjects();
  }

  getListProjects() {
    this.projectsService.getProjectsList().subscribe({
      next: (projects: Projects) => {
        if (projects.data instanceof Array) {
          this.projects = projects.data;
        }
      },
      error: (error) => {
        console.error('Error:', error);
      },
    });
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }

  openDialogProject(project?: Project): void {
    const dialog = this.dialog.open(ProjectModalComponent, {
      width: '60vw',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: {
        project,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        if (result.reload) {
          this.getListProjects();
        } else if (result.data) {
          const index = this.projects.findIndex(
            (project) => project._id === result.data._id
          );
          if (index !== -1) {
            this.projects[index] = { ...this.projects[index], ...result.data };
            console.log('this.projects', this.projects);
          }
        }
      }
    });
  }
}
