import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { Media } from '@core/interfaces/media.interface';
import { MultimediasService } from '@core/services/programs/multimedias.service';
import { StepsService } from '@core/services/programs/steps.service';
import { Steps } from '@core/interfaces/Steps.interface';
import { FilterService } from '@core/services/filters/filter.service';

@Component({
  selector: 'app-dialog-media',
  templateUrl: './dialog-media.component.html',
  styleUrls: ['./dialog-media.component.scss'],
})
export class DialogMediaComponent implements OnInit, OnDestroy {
  public archivos: File | null = null;
  public pageIndex: number = 1;
  public pageSize: number = 50;
  public totalItems?: number;
  displayedColumns: string[] = ['name', 'fileUrl', 'type', 'action'];
  public listMultimedias: Media[] = [];
  dataSource = new MatTableDataSource<Media>(this.listMultimedias);
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  private filterSubscription: Subscription | undefined;
  public files: File[] = [];
  public searchValue: string = '';
  public multimediaCtrl = new FormControl();

  public folder: string = '';
  public listSelect: any[] = [
    { value: 'enlace' },
    { value: 'multimedia' },
    { value: 'cargar' },
  ];
  public step!: FormGroup;
  public fileData: { name: string; base: any } = { name: '', base: '' };
  public idMultimedia: string = '';

  constructor(
    private fb: FormBuilder,
    public _stepsService: StepsService,
    private _mediasService: MultimediasService,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogMediaComponent>
  ) {}

  ngOnInit(): void {
    this.getListMultimedias();

    this.step = this.fb.group({
      numberStep: [''],
      interaction: ['Informativo', Validators.required],
      question: [''],
      description: [''],
      typeMultimedia: [''],
      media: [''],
      name: [''],
      title: [''],
      fileUrl: [''],
      files: [''],
    });

    if (this.data && this.data.step !== undefined) {
      this.step.patchValue({
        numberStep: this.data.step.numberStep,
        interaction: this.data.step.interaction,
        question: this.data.step.question,
        description: this.data.step.description,
        media: this.data.step.media ? this.data.step.media : '',
        fileUrl: this.data.step.media ? this.data.step.media.fileUrl : '',
      });
    }

    this.step.get('files')?.valueChanges.subscribe((files) => {
      if (files && files.length > 0) {
        this.step.get('fileUrl')?.disable();
      } else {
        this.step.get('fileUrl')?.enable();
      }
    });

    // Suscripción a los cambios de filtros
    this.filterSubscription = this.filterService.filter$.subscribe(
      (filters) => {
        this.searchValue = filters.searchValue;
        // Obtener la lista de programas paginada con los filtros aplicados
        this.getListMultimedias(this.searchValue);
      }
    );
  }

  ngOnDestroy(): void {
    this.step.reset();
    this.multimediaCtrl.reset();
    this.listMultimedias = [];
    this.files = [];
    // Destruir la suscripción
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  getListMultimedias(searchValue: string = '') {
    if (searchValue) {
      this.pageIndex = 1;
    }
    this._mediasService
      .getListMedia(this.pageIndex, this.pageSize, searchValue)
      .subscribe(
        (resp: any) => {
          this.listMultimedias = resp.results.data;
          this.totalItems = resp.results.pagination.total;
          this.dataSource.data = this.listMultimedias;
          this.dataSource = new MatTableDataSource(this.listMultimedias);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  editStepById(id: string) {
    this._stepsService.updatedStep(id, this.step.value).subscribe(
      (resp: Steps) => {
        if (resp.ok) {
          this.handleMediaUpload();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async eventStep() {
    if (this.data && this.data.step !== undefined) {
      await this.editStepById(this.data.step._id);
      this.dialogRef.close();
    } else {
      this.dialogRef.close(this.step.value);
    }
  }

  registerMedia() {
    let newMedia = {
      name: this.step.value.title,
      fileUrl: this.step.value.fileUrl,
    };

    this._mediasService.guardarLinkVideo(newMedia).subscribe(
      (res: any) => {
        this.handleMediaUpload();
        this.step.value.media = res;
        this.step.value.fileUrl = '';
        this.step.value.title = '';
      },
      (err) => {
        console.error('Error al registrar la media:', err);
        let errorMessage = 'Se ha presentado un error al registrar la media';
        if (err && err.error && err.error.message) {
          errorMessage = err.error.message;
        }
        Swal.fire('Registro de medios', errorMessage, 'error');
      }
    );
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    this.getListMultimedias(this.searchValue);
  }

  cargarArchivo() {
    const formData = new FormData();
    formData.append('carpeta', this.step.value.title || this.archivos?.name);
    if (this.archivos instanceof File) {
      formData.append('file', this.archivos);
    }

    Swal.fire({
      title: 'Subiendo archivo...',
      text: 'Por favor, espere unos segundos',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    this._mediasService.cargarMedia(formData).subscribe(
      (res: any) => {
        if (res.ok) {
          this.step.value.media = res.data;
        }
      },
      (err) => {
        console.error('Error al cargar la media:', err);
        let errorMessage = 'Se ha presentado un error al cargar la media';
        if (err && err.error && err.error.message) {
          errorMessage = err.error.message;
        }
        Swal.fire('Carga de medios', errorMessage, 'error');
      }
    );
  }

  selectMedia(event: any) {
    this.step.value.media = event.value;
  }

  onSelect(event: any) {
    if (event.addedFiles.length > 0) {
      const file = event.addedFiles[0];
      this.files.push(file);
      this.archivos = file;
      this.step.get('files')?.setValue(this.files);
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    this.step.get('files')?.setValue(this.files);
    if (this.files.length === 0) {
      this.step.get('fileUrl')?.enable();
    }
  }

  checkFileUrl() {
    if (this.step.get('fileUrl')?.value) {
      this.files = [];
      this.step.get('files')?.reset();
    }
  }

  obtenerCarpetaPorFormato(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (extension) {
      if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        return 'img';
      } else if (['doc', 'docx', 'xlsx', 'pdf'].includes(extension)) {
        return 'documents';
      } else if (['mp3'].includes(extension)) {
        return 'audio';
      }
    }
    return 'otros';
  }

  saveMedia() {
    if (this.files.length > 0) {
      this.cargarArchivo();
    } else if (this.step.get('fileUrl')?.value) {
      this.registerMedia();
    }
  }

  async handleMediaUpload(): Promise<void> {
    Swal.fire({
      icon: 'success',
      title: '<strong>¡Éxito!</strong>',
      text: 'Se ha añadido la multimedia al paso correctamente',
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      color: '#1d2c4d',
      confirmButtonColor: '#262D48',
      background: '#ffffff',
      width: '28rem',
    });
  }

  close() {
    this.dialogRef.close();
  }
}
