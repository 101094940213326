<app-card
  title="Proyectos"
  [titleButton]="'Agregar Proyectos'"
  (action)="openDialogProject()"
>
  <div class="projects-container">
    <mat-card class="project-card tagus-card" *ngFor="let project of projects">
      <div class="card-header">
        <img class="project-logo" [src]="project.logo" [alt]="project.name" />
      </div>
      <div class="card-body">
        <h3 class="project-title">{{ project.name }}</h3>
        <p class="project-description">{{ project.description }}</p>
      </div>
      <div class="card-footer">
        <button
          mat-button
          class="default-btn tagus bg-main-color-orange-button"
          (click)="openDialogProject(project)"
        >
          <i class="fas fa-edit"></i>
          Editar
        </button>
        <button mat-button class="default-btn tagus bg-color-blue-button">
          <i class="fas fa-trash-alt"></i>
          Eliminar
        </button>
      </div>
    </mat-card>
  </div>
</app-card>
