<app-card [title]="title">
  <form autocomplete="off" novalidate [formGroup]="linesForm">
    <h5 class="fw-semibold mb-9 tertiary-blue-color">
      Ingresa los datos de la Nueva Línea
    </h5>
    <div class="row mt-20">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="row tagus-form-group">
          <div class="col-lg-9 col-md-9 col-sm-12">
            <app-autocomplete
              (optionSelected)="selectIndicative($event)"
              [title]="'Linea'"
              [valueData]="dataSelect"
              [placeholder]="'Seleccione una línea'"
              [data]="listIndicatives"
              [displayProperties]="['image', 'name', 'indicative']"
              [propertyClass]="['img', 'text', 'text']"
            ></app-autocomplete>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <mat-form-field appearance="fill">
              <img
                matPrefix
                [src]="lineImg"
                width="24px"
                height="24px"
                style="margin: 5px"
              />

              <mat-label>Indicativo</mat-label>
              <input
                type="text"
                matInput
                rows="10"
                formControlName="indicative"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="
              linesForm.controls['name'].invalid &&
              (linesForm.controls['name'].dirty ||
                linesForm.controls['name'].touched)
            "
            class="text-danger"
          >
            <div *ngIf="linesForm.controls['name'].errors?.['required']">
              El nombre es requerido.
            </div>
            <div *ngIf="linesForm.controls['name'].errors?.['minlength']">
              El nombre debe tener al menos 2 caracteres.
            </div>
            <div *ngIf="linesForm.controls['name'].errors?.['maxlength']">
              El nombre no puede tener más de 50 caracteres.
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label class="fw-semibold subtitle-card-color">Descripción</label>
            <mat-form-field appearance="fill">
              <i class="ri-information-line"></i>
              <mat-label>Describe la linea...</mat-label>
              <textarea
                matInput
                rows="3"
                formControlName="description"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>

  <mat-divider></mat-divider>

  <mat-tab-group animationDuration="2000ms">
    <mat-tab label="Grupos">
      <h5 class="fw-semibold mb-20 me-3 tertiary-blue-color">Listado Grupos</h5>
      <app-table-data
        [data]="listGroup"
        [dataSource]="dataSource"
        [columns]="displayedColumns"
        [pageSizeOptions]="[10, 15, 20]"
        [enableEditing]="true"
        [editButton]="true"
        [deleteButton]="true"
        [showAddRow]="true"
        [viewButton]="false"
        [stateButton]="false"
        [selectButton]="false"
        [codeqrButton]="false"
        (deleteEvent)="deleteGroup($event)"
        (saveEvent)="saveGroup($event)"
        (addEvent)="addNewGroupRow()"
        (cancelEvent)="cancelGroup($event)"
        (pageChange)="onPageChange($event)"
      ></app-table-data>
    </mat-tab>
    <mat-tab label="Chabot">
      <h5 class="text-center tertiary-blue-color">
        Formulario de registro en Chatbot
      </h5>
      <p>
        <strong>
          Ten en cuenta que este es el método mediante el cual los usuarios se
          registrarán en el bot.
        </strong>
        Es fundamental que diligencies la información de manera correcta, ya que
        cualquier error o inconsistencia en esta etapa podría afectar
        directamente la experiencia del usuario y la funcionalidad del sistema.
        Asegúrate de revisar detalladamente todos los pasos y validar que los
        datos ingresados sean precisos. Además, ten presente que cualquier
        modificación posterior a este flujo de registro puede impactar en la
        forma en que los usuarios interactúan con el bot.
      </p>

      <div class="recent-files-table table-responsive">
        <div class="container col-lg-12 col-md-12 col-sm-12">
          <app-add-edit-messages-table
            [questionForm]="questionForm"
            [questions]="questions"
            [displayedColumns]="[
              'position',
              'question',
              'type',
              'variable',
              'options',
              'actions',
            ]"
            (updateData)="updateQuestion($event)"
            (saveData)="updateQuestion($event)"
            (deleteData)="updateQuestion($event)"
          ></app-add-edit-messages-table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="text-end">
    <button
      mat-flat-button
      class="bg-main-color-blue fw-semibold"
      (click)="saveLine()"
    >
      Guardar
    </button>
  </div>
</app-card>
