import { Component } from '@angular/core';
import { SpinnerService } from '@core/services/shared/spinner.service';

@Component({
  selector: 'app-spinner',
  template: `
    <div class="loader-container" *ngIf="isLoading">
      <div class="circle">
        <div class="letter-c">C</div>
        <div class="letter-h">H</div>
      </div>
    </div>
  `,
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent {
  isLoading = false;

  constructor(public spinnerService: SpinnerService) {
    this.spinnerService.loadingStatus.subscribe((status) => {
      this.isLoading = status;
    });
  }
}
