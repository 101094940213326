<div class="h-100vh pt-50 pb-50">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="tagus-form ms-auto me-auto">
        <div class="title">
          <div class="d-flex mb-10 align-items-center">
            <h2 class="mb-0 me-30">¿Has olvidado tu contraseña?</h2>
            <img src="assets/img/icons/2_pajaro.png" alt="logo-icon" />
          </div>
          <p>
            Ingresa tu correo electrónico y te enviaremos instrucciones para
            restablecer tu contraseña
          </p>
        </div>
        <form autocomplete="off" [formGroup]="resetPasswordForm">
          <div class="bg-white border-radius pt-20 ps-20 pe-20">
            <div class="tagus-form-group without-icon">
              <label class="d-block mb-12 fw-semibold gray-color">
                Email Address
                <span>*</span>
              </label>
              <mat-form-field appearance="fill">
                <mat-label>Enter email address</mat-label>
                <input
                  matInput
                  formControlName="email"
                  placeholder="Correo electrónico"
                  required
                />

                <mat-error
                  *ngIf="resetPasswordForm.get('email')?.hasError('required')"
                >
                  El correo es requerido
                </mat-error>
                <mat-error
                  *ngIf="resetPasswordForm.get('email')?.hasError('email')"
                >
                  Ingrese un correo válido
                </mat-error>
                <mat-error
                  *ngIf="
                    resetPasswordForm.get('email')?.hasError('invalidDomain')
                  "
                >
                  Solo se permiten correos de fundacioncapital.org
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button
            mat-flat-button
            class="tagus d-block fw-semibold"
            (click)="forgotPassword()"
          >
            Enviar enlace de reinicio
          </button>
          <div class="text-center back-to-login">
            <a
              routerLink="/authentication/login"
              class="d-inline-block main-color fw-medium position-relative"
            >
              <i class="flaticon-chevron-1"></i>
              Regresar
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
