import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Auth, Login } from '@core/interfaces/auth.interface';
import { AuthService } from '@core/services/authentication/auth.service';
import { ResponsePublicKey } from '../interfaces/response-publicKey.interface';
import { SweetAlertService } from '@core/services/sweet-alert.service';
import { AuthStateService } from '@core/states/auth-state.service';
import { ProjectsService } from '@core/services/configurations/projects.service';
import { Project, Projects } from '@core/interfaces/project.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  hide = true;
  public publicKey: string = '';

  public loginForm = this.fb.group({
    email: [
      '',
      [Validators.required, Validators.email, this.emailDomainValidator],
    ],
    password: ['', Validators.required],
    remember: [false],
  });

  groupForm = this.fb.group({
    project: [null, Validators.required],
  });

  isLoading: boolean = false;
  public listProject: Project[] = [];
  public activeListProject: boolean = false;
  public activeButton: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private projectService: ProjectsService,
    private authStateService: AuthStateService,
    private sweetAlertService: SweetAlertService
  ) {}

  emailDomainValidator(control: any) {
    const email = control.value;
    if (email && email.indexOf('@') !== -1) {
      const [, domain] = email.split('@');
      if (domain.toLowerCase() !== 'fundacioncapital.org') {
        return { invalidDomain: true };
      }
    }
    return null;
  }

  async login() {
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.authService.getPublicKey().subscribe(
      (resp: ResponsePublicKey) => {
        this.publicKey = resp.data;

        if (this.publicKey) {
          const encryptedPassword = this.authService.encryptData(
            this.loginForm.value.password || '',
            this.publicKey
          );

          const loginData: Login = {
            email: this.loginForm.value.email || '',
            password: encryptedPassword,
          };

          this.authService.loginService(loginData).subscribe(
            (resp: Auth) => {
              if (resp.ok) {
                this.authStateService.setUserId(resp.data?._id || null);
                this.authStateService.setToken(resp.data?.token || null);
                this.authStateService.setNameUser(resp.data?.name || null);
                this.authStateService.setLastNameUser(
                  resp.data?.last_name || null
                );
                this.authStateService.setEmailUser(resp.data?.email || null);
                this.authStateService.setImgUser(resp.data?.img || null);
                this.authStateService.setProfileUser(
                  resp.data?.profile ? [resp.data.profile] : null
                );

                const profile = resp.data.profile;
                if (
                  profile?.includes('admin') ||
                  profile?.includes('superadmin')
                ) {
                  this.getProject();
                  this.isLoading = false;
                } else {
                  this.authStateService.setProject(resp.data?.project || null);
                  this.router.navigate(['/']);
                }

                this.isLoading = false;
              } else {
                this.isLoading = false;
                this.sweetAlertService.showErrorAlert(
                  'Upps!!',
                  resp.message || 'Error de autenticación, verifique sus datos'
                );
              }
            },
            (error) => {
              this.isLoading = false;
              this.sweetAlertService.showErrorAlert(
                'Upps!!',
                error.error.message ||
                  'Error de autenticación, verifique sus datos'
              );
            }
          );
        }
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  ingresar() {
    if (!this.activeButton) {
      this.sweetAlertService.showWarningAlert(
        'Upps!!',
        'Seleccione un proyecto'
      );
      return;
    }
    this.router.navigate(['/']);
  }

  getProject() {
    this.projectService.getProjectsList().subscribe(
      (resp: Projects) => {
        if (Array.isArray(resp.data)) {
          this.listProject = resp.data;
          this.activeListProject = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleOptionSelected(event: Project) {
    this.authStateService.setProject(event.name || null);
    this.activeButton = true;
  }
}
