<div
  class="footer-area text-center bg-white border-top-radius box-shadow"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.component-dark-theme]="themeService.isDark()"
  [class.card-border-radius]="themeService.isCardBorderRadius()"
>
  <p>
    © CON HÉCTOR - es orgullosamente propiedad de
    <a
      href="https://www.fundacioncapital.org"
      class="fw-semibold"
      target="_blank"
    >
      Fundación Capital
    </a>
  </p>
</div>
