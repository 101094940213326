import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhone',
})
export class FormatPhonePipe implements PipeTransform {
  transform(value: string): string {
    const phoneNumber = value.replace(/\D/g, '');

    const areaCode = phoneNumber.slice(0, 3);
    const middlePart = phoneNumber.slice(3, 6);
    const lastPart = phoneNumber.slice(6);

    let formattedPhoneNumber = '';

    if (phoneNumber.length > 0) {
      formattedPhoneNumber = `(${areaCode})`;

      if (middlePart) {
        formattedPhoneNumber += ` ${middlePart}`;
      }

      if (lastPart) {
        formattedPhoneNumber += `-${lastPart}`;
      }
    }

    return formattedPhoneNumber;
  }
}
