import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {
  control = new FormControl();
  filteredOptions: Observable<any[]> = new Observable<any[]>();

  @Input() data: any[] = [];
  @Input() title = '';
  @Input() placeholder = '';
  @Input() displayProperties: string[] = [];
  @Input() propertyClass: string[] = [];
  @Input() valueData: any;

  @Output() optionSelected = new EventEmitter<any>();

  ngOnInit() {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.data.slice();
      })
    );
  }

  displayFn(option: any): string {
    return option && option.name ? option.name : '';
  }

  onSelectOption(option: any) {
    this.optionSelected.emit(option);
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.data.filter((option) =>
      this.displayProperties.some(
        (prop) =>
          option[prop] &&
          option[prop].toString().toLowerCase().includes(filterValue)
      )
    );
  }

  last(index: number): boolean {
    return index === this.displayProperties.length - 1;
  }
}
