import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { Line } from '@core/interfaces/lines.interface';
import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';
import { AuthStateService } from '@core/states/auth-state.service';
import { SweetAlertService } from '@core/services/sweet-alert.service';
import { CampaignsService } from '@core/services/campaigns/campaigns.service';
import { DialogViewCampaignComponent } from '../dialog-view-campaign/dialog-view-campaign.component';

@Component({
  selector: 'app-lists-line',
  templateUrl: './lists-line.component.html',
  styleUrls: ['./lists-line.component.scss'],
})
export class ListsLineComponent implements OnInit {
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  public pageIndex: number = 1;
  public pageSize: number = 10;
  public totalItems?: number;

  public titleButton = 'Crear Línea';

  displayedColumns: string[] = ['name', 'indicative', 'action'];

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public listLine: Line[] = [];
  dataSource = new MatTableDataSource<Line>(this.listLine);

  active = true;
  inactive = true;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    public authStateService: AuthStateService,
    public _linesGroupsService: LinesGroupsService,
    public sweetAlertService: SweetAlertService,
    private campaignsService: CampaignsService
  ) {}

  ngOnInit(): void {
    this.authStateService.project$.subscribe((project) => {
      this.getListLines();
    });
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe((event: PageEvent) => {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
        this.getListLines();
      });
    }
  }

  getListLines(): void {
    this._linesGroupsService
      .getListLines(this.pageIndex, this.pageSize)
      .subscribe(
        (resp: any) => {
          if (resp.ok) {
            this.listLine = resp.data;
            this.dataSource.data = this.listLine;
            this.totalItems = resp.data.length;
            this.dataSource = new MatTableDataSource(this.listLine);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  editLine(data: any): void {
    this.router.navigate(['lines/edit-line', data._id]);
  }

  /**
   * Maneja el cambio de página del paginador.
   * @param event Evento de cambio de página.
   */
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    });
    this.getListLines();
  }

  deleteLine(data: Line): void {
    console.log('data', data);
  }

  camapaingLine(data: any) {
    console.log(data);
    this._linesGroupsService.getLinesById(data._id).subscribe((resp: any) => {
      if (resp.ok) {
        const lineData = resp.data;
        const groups = lineData.groups.map((group: any) => group.name);
        this.sweetAlertService
          .showSelectAlert('Selecciona el Grupo', 'Crea la campaña', groups)
          .then((result) => {
            if (result.isConfirmed) {
              const groupSelect = lineData.groups.find(
                (group: any) => group.name === groups[result.value]
              );

              if (groupSelect) {
                console.log(groupSelect);
                this.editCampain(
                  groupSelect._id.toString(),
                  data._id.toString(),
                  data.name
                );
              }
            }
          });
      }
    });
  }

  editCampain(groupId: string, lineId: string, lineName: string): void {
    this.campaignsService.getCampaignByGroup(groupId).subscribe(
      (response) => {
        if (response.ok && response.data) {
          const dialogRef = this.dialog.open(DialogViewCampaignComponent, {
            width: '60vw',
            data: response.data,
          });

          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.router.navigate(['lines/line-campaign', groupId], {
                queryParams: { name: lineId, line: lineName },
              });
            }
          });
        } else {
          this.router.navigate(['lines/line-campaign', groupId], {
            queryParams: { name: lineId, line: lineName },
          });
        }
      },
      (error) => {
        this.sweetAlertService.showErrorAlert('Error', error.error.msg);
        // this.router.navigate(['lines/line-campaign', data.id], {
        //   queryParams: { name: data.name, line: this.lineName },
        // });
      }
    );
  }
}
