import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Multimedias,
  Multimedia,
} from '@core/interfaces/multimiedias.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MultimediasService {
  public urlBackend: string = environment.base_url;
  public headers: any;
  public UrlService = 'multimedias';

  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  private getHeadersCreate(): HttpHeaders {
    return new HttpHeaders()
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getFoldersMultimedias(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get<any>(`${this.urlBackend}/${this.UrlService}/folder`, {
      headers: headers,
    });
  }

  getMultimedias(folder: string): Observable<Multimedias> {
    const headers = this.getHeaders();
    return this.http.get<Multimedias>(
      `${this.urlBackend}/${this.UrlService}?folder=${folder}`,
      {
        headers: headers,
      }
    );
  }

  getMultimedia(folder: string, filename: string): any {
    const headers = this.getHeaders();
    return this.http.get(
      `${this.urlBackend}/${this.UrlService}/file?folder=${folder}&filename=${filename}`,
      {
        headers: headers,
      }
    );
  }

  addMultimedia(
    folder: string,
    name: string,
    file: File
  ): Observable<Multimedias> {
    const headers = this.getHeadersCreate();
    const formData: FormData = new FormData();
    formData.append('file', file);

    const requestUrl = `${this.urlBackend}/${this.UrlService}?folder=${encodeURIComponent(folder)}&name=${encodeURIComponent(name)}`;

    return this.http.post<Multimedias>(requestUrl, formData, { headers });
  }
}
