<div *ngFor="let item of transformedData; let i = index">
  <div class="button_add">
    <button
      class="ri-delete-bin-line default-btn cancel-button bg-main-color-orange-button"
      (click)="deleteFirstLevelComplete(item)"
    ></button>
  </div>
  <div class="container">
    <p-organizationChart
      [value]="[item]"
      selectionMode="multiple"
      [(selection)]="selectedNodes"
    >
      <ng-template let-node pTemplate="person">
        <div
          class=""
          [attr.id]="'node-' + node.data.id"
          (click)="deleteOrUpdate(node.data, transformedData)"
          (contextmenu)="onNodeContextMenu($event, node.data, transformedData)"
          [ngClass]="node.styleClass"
        >
          <div class="node-content">
            <div class="font-bold node-title">{{ node.data.name }}</div>
          </div>
        </div>
      </ng-template>
    </p-organizationChart>
  </div>
</div>
