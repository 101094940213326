import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User, Users } from '@core/interfaces/User.interface';
import { UsersService } from '@core/services/configurations/users.service';
import Swal from 'sweetalert2';
import { CreateEditUserComponent } from '../dialog/create-edit-user/create-edit-user.component';
import { SweetAlertService } from '@core/services/sweet-alert.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'img',
    'name',
    'last_name',
    'email',
    'phone',
    'project',
    'state',
    'action',
  ];
  public listUser: User[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  public dataSource = new MatTableDataSource<User>(this.listUser);

  constructor(
    public dialog: MatDialog,
    private _usersService: UsersService,
    private sweetAlertService: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
  }

  getUsers(): void {
    this._usersService.getUsers().subscribe(
      (resp: Users) => {
        this.listUser = resp.data as User[];
        this.dataSource.data = this.listUser;
        this.dataSource = new MatTableDataSource(this.listUser);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  editUser(event: any): void {
    this.openCreateUserDialog(event);
  }

  openCreateUserDialog(event?: any): void {
    const dialog = this.dialog.open(CreateEditUserComponent, {
      width: '60vw',
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '100ms',
      data: {
        event,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getUsers();
      }
    });
  }

  stateUser(event: User): void {
    const newState = event.state === 'A' ? 'I' : 'A';

    const data = { state: newState };
    const idUser = event._id || '';

    this._usersService.UpdateUser(idUser, data).subscribe({
      next: (resp: Users) => {
        this.sweetAlertService.showSuccessAlert('👌', `${resp.message}`);
        this.getUsers();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  deleteUser(event: any): void {
    this._usersService.deleteUser(event._id).subscribe({
      next: (resp) => {
        Swal.fire({
          title: 'Usuario eliminado',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        this.getUsers();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }
}
