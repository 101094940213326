<div class="col-lg-12 col-md-12 col-sm-12 form-container">
  <div class="col-lg-12 col-md-12 col-sm-12 tagus-form-group">
    <!-- <h3 class="section-title mb-25 mt-10">Gestión de Preguntas de la Campaña</h3> -->
    <form
      [formGroup]="questionForm"
      (ngSubmit)="addQuestion()"
      class="row tagus-form-group"
    >
      <!-- Pregunta Input -->
      <div class="form-group" class="col-lg-4 col-md-4 col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <i class="ri-questionnaire-fill"></i>
          <mat-label>Descripción</mat-label>
          <!-- <input matInput placeholder="Escribe una pregunta" formControlName="question"> -->
          <textarea
            style="resize: none"
            matInput
            rows="1"
            formControlName="question"
            placeholder="Escribe una pregunta"
          ></textarea>
        </mat-form-field>
      </div>
      <!-- Tipo de Pregunta Select -->
      <div
        class="form-group"
        [ngClass]="{
          'col-lg-2 col-md-2 col-sm-12': isMultipleChoice,
          'col-lg-4 col-md-4 col-sm-12': !isMultipleChoice,
        }"
      >
        <mat-form-field appearance="fill" class="w-100">
          <i class="ri-checkbox-multiple-blank-line"></i>
          <mat-label>Tipo de Pregunta</mat-label>
          <mat-select
            formControlName="type"
            (selectionChange)="onTypeChange($event)"
          >
            <mat-option value="text">Texto</mat-option>
            <mat-option value="multiple-choice">Opción Múltiple</mat-option>
            <mat-option value="informative">Informativo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Opciones Input -->
      <div *ngIf="isMultipleChoice" class="col-lg-4 col-md-4 col-sm-12">
        <mat-form-field appearance="fill" class="w-100">
          <i class="ri-chat-settings-line"></i>
          <mat-label>Opción 1, Opción 2, ...</mat-label>
          <input
            matInput
            placeholder="Opción 1, Opción 2, Opción 3"
            formControlName="options"
          />
        </mat-form-field>
      </div>
      <!-- Botón de Agregar -->
      <div
        [ngClass]="{
          'col-lg-2 col-md-2 col-sm-12': isMultipleChoice,
          'col-lg-4 col-md-4 col-sm-12': !isMultipleChoice,
        }"
      >
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="questionForm.invalid"
          class="full-height-button w-100 bg-color-blue-button"
        >
          {{ isMultipleChoice ? 'Agregar' : 'Agregar Pregunta' }}
        </button>
      </div>
    </form>
  </div>
  <!-- Tabla de Preguntas -->
  <div
    class="col-lg-12 col-md-12 col-sm-12"
    style="max-height: 500px; min-height: 100%; overflow-y: auto"
  >
    <div class="recent-files-table table-responsive">
      <table
        mat-table
        [dataSource]="dataSource"
        cdkDropList
        (cdkDropListDropped)="drop($event)"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef>Posición</th>
          <td mat-cell *matCellDef="let question; let i = index">
            {{ i + 1 }}
          </td>
        </ng-container>
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef>Pregunta</th>
          <td mat-cell *matCellDef="let question; let i = index">
            <ng-container *ngIf="editingIndex === i; else viewMode">
              <textarea
                class="inputEdit"
                matInput
                rows="1"
                [(ngModel)]="editedQuestion.question"
              ></textarea>
            </ng-container>

            <ng-template #viewMode>
              <span
                matTooltip="{{ question.question }}"
                matTooltipPosition="above"
              >
                {{
                  question?.question
                    ? (question?.question | truncate: 20)
                    : question?.question
                }}
              </span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Tipo</th>
          <td mat-cell *matCellDef="let question; let i = index">
            <ng-container *ngIf="editingIndex === i; else viewType">
              <mat-select [(ngModel)]="editedQuestion.type">
                <mat-option value="text">Texto</mat-option>
                <mat-option value="multiple-choice">Opción Múltiple</mat-option>
                <mat-option value="informative">Informativo</mat-option>
              </mat-select>
            </ng-container>
            <ng-template #viewType>{{ question.type }}</ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="variable">
          <th mat-header-cell *matHeaderCellDef>Variable</th>
          <td mat-cell *matCellDef="let question; let i = index">
            <ng-container *ngIf="editingIndex === i; else viewMode">
              <textarea
                class="inputEdit"
                matInput
                rows="1"
                [(ngModel)]="editedQuestion.variable"
              ></textarea>
            </ng-container>

            <ng-template #viewMode>
              <span
                matTooltip="{{ question.variable }}"
                matTooltipPosition="above"
              >
                {{ question.variable | truncate: 20 }}
              </span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Opciones</th>
          <td mat-cell *matCellDef="let question; let i = index">
            <ng-container
              *ngIf="
                editingIndex === i && editedQuestion.type === 'multiple-choice';
                else viewOptions
              "
            >
              <input matInput [(ngModel)]="editedQuestion.options" />
            </ng-container>
            <ng-template #viewOptions>
              <span
                matTooltip="{{
                  question.options
                    ? question.options.join(', ')
                    : ('N/A' | truncate: 20)
                }}"
                matTooltipPosition="above"
              >
                {{ question.options ? question.options.join(', ') : 'N/A' }}
              </span>
            </ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let question; let i = index">
            <ng-container *ngIf="editingIndex === i; else editMode">
              <button
                mat-icon-button
                class="blue-color"
                (click)="saveQuestion(i)"
              >
                <mat-icon>check</mat-icon>
              </button>
              <button mat-icon-button class="blue-color" (click)="cancelEdit()">
                <mat-icon>cancel</mat-icon>
              </button>
            </ng-container>
            <ng-template #editMode>
              <button
                mat-icon-button
                class="blue-color"
                (click)="editQuestion(question, i)"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                class="blue-color"
                (click)="deleteQuestion(question)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </ng-template>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          cdkDrag
        ></tr>
      </table>
    </div>
  </div>
</div>
