import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Line, Lines } from '@core/interfaces/lines.interface';
import { LinesGroupsService } from '@core/services/linesGroups/lines-groups.service';
import { MatchService } from '@core/services/programs/match.service';
import { AuthStateService } from '@core/states/auth-state.service';
import { DiagramComponent } from '@modules/programs/components/diagram/diagram.component';
import { DialogAddLevelComponent } from '@modules/programs/components/dialog-add-level/dialog-add-level.component';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-list-match',
  templateUrl: './list-match.component.html',
  styleUrls: ['./list-match.component.scss'],
})
export class ListMatchComponent {
  selectedDiagram: any = null;
  activeDiagram: boolean = false;
  listLine: Line[] = [];
  dataGroup: any[] = [];

  activeMatch: any;

  myControl = new FormControl<string | Line>('');
  filteredOptions!: Observable<Line[]>;
  public idLine: string = '';
  selectedChips: { [groupName: string]: number } = {};
  expandedPanels: { [key: number]: number | null } = {};

  @ViewChild(DiagramComponent) diagramComponent!: DiagramComponent;

  constructor(
    public dialog: MatDialog,
    private matchService: MatchService,
    public authStateService: AuthStateService,
    private _linesGroupsService: LinesGroupsService
  ) {
    this.authStateService.project$.subscribe((project) => {
      this.selectedDiagram = null;
      this.listLine = [];
      this.dataGroup = [];
      this.getListLines();
    });
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name) : this.listLine.slice();
      })
    );
  }

  displayFn(line: Line): string {
    return line && line.name ? line.name : '';
  }

  private _filter(name: string): Line[] {
    const filterValue = name.toLowerCase();
    return this.listLine.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  getListLines(): void {
    this._linesGroupsService.getListLines().subscribe(
      (resp: Lines) => {
        if (resp.ok) {
          if (Array.isArray(resp.data)) {
            this.listLine = resp.data;
          }
        }
      },
      (err) => console.error(err)
    );
  }

  selectDiagram(item: any, info: any, index: number) {
    this.activeDiagram = true;
    this.activeMatch = info;
    this.selectedDiagram = item;
    this.selectedChips[info.group.name] = index;
    this.changeInfo(item);
  }
  filterGroupsByLines(id: string) {
    this.idLine = id;
    this.matchService.getListMatchByIdLine(id).subscribe(
      (res: any) => {
        if (res.ok) {
          this.dataGroup = res.data || [];
          this.expandedPanels = {};
        }
      },
      (err) => console.error(err)
    );
  }

  optionSelected(line: Line) {
    this.idLine = line._id || '';
    this.filterGroupsByLines(this.idLine);
  }

  registerNewNivel(data: any, id: any) {
    const newLevel = {
      level: data.level,
      subPrograms: {
        position: data.position,
        program: data.program,
      },
    };

    const group = this.dataGroup.find((g) => g.group === id);
    if (group) {
      group.levels.push(newLevel);
      this.expandedPanels[id] = group.levels.length - 1;
    }
  }

  changeInfo(data: any) {
    if (data?.method === 'update' || data?.method === 'delete') {
      this.dataGroup = this.dataGroup.map((program) =>
        program.group._id === data.response.group._id ? data.response : program
      );
    }
    this.activeDiagram = true;
  }

  addNewFirstLevel(match: any, info: any) {
    const data = {
      match: match._id,
      level: 1,
      position: (info.length + 1).toString(),
      program: '',
      children: [],
    };
    const dialogRef = this.dialog.open(DialogAddLevelComponent, {
      width: '70vw',
      data: { isUpdate: 'returnsId', body: data },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const previousExpandedIndex = this.expandedPanels[match.group._id];
        this.filterGroupsByLines(this.idLine);
        this.expandedPanels[match.group._id] =
          previousExpandedIndex ?? info.length;
      }
    });
  }

  isPanelExpanded(groupIndex: number, panelIndex: number): boolean {
    return this.expandedPanels[groupIndex] === panelIndex;
  }

  panelOpened(groupIndex: number, panelIndex: number): void {
    this.expandedPanels[groupIndex] = panelIndex;
  }

  panelClosed(groupIndex: number, panelIndex: number): void {
    if (this.expandedPanels[groupIndex] === panelIndex) {
      this.expandedPanels[groupIndex] = null;
    }
  }
}
