import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, Login, reset_password } from '@core/interfaces/auth.interface';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ResponsePublicKey } from '@modules/auth/interfaces/response-publicKey.interface';
import * as forge from 'node-forge';
import { AuthStateService } from '@core/states/auth-state.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public urlBack: string = environment.base_url;

  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {}

  loginService(data: Login): Observable<Auth> {
    return this.http.post<Auth>(`${this.urlBack}/auth/login`, data).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de inicio de sesión';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de inicio de sesión: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(error);
      })
    );
  }

  forgotPasswordService(email: string): Observable<AuthService> {
    return this.http
      .post<AuthService>(`${this.urlBack}/auth/forgot-password`, { email })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de restablecimiento de contraseña';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de restablecimiento de contraseña: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  getIdentity(): boolean {
    let token;

    this.authStateService.token$.subscribe((data) => {
      token = data;
    });

    const helper = new JwtHelperService();
    if (token === null) {
      return false;
    }
    if (helper.isTokenExpired(token)) {
      return false;
    }

    return true;
  }

  validateTokenEmail(token: string): Observable<any> {
    const params = new HttpParams().set('token', token);

    return this.http
      .get(`${this.urlBack}/auth/valid-email`, { params: params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de validación de correo';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de validación de correo: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(errorMessage);
        })
      );
  }

  resetPassword(data: any): Observable<any> {
    return this.http.post(`${this.urlBack}/auth/reset-password`, data, {}).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage =
          'Ocurrió un error en la solicitud de cambio de contraseña';
        if (error.error instanceof ErrorEvent) {
          // Error del cliente, como red no disponible
          console.error('Error del cliente:', error.error.message);
        } else {
          // El servidor devolvió un código de error
          console.error(
            `Error en la solicitud de cambio de contraseña: ${error.status}, ` +
              `Mensaje de error: ${error.error.msg}`
          );
          errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
        }
        // Devuelve el mensaje de error al componente
        return throwError(errorMessage);
      })
    );
  }

  getPublicKey(): Observable<ResponsePublicKey> {
    return this.http
      .get<ResponsePublicKey>(`${this.urlBack}/common/public-key`)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de clave pública';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de clave pública: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }

          return throwError(errorMessage);
        })
      );
  }

  encryptData(data: string, key: string): string {
    const formattedKey = key.trim();
    try {
      const publicKey = forge.pki.publicKeyFromPem(formattedKey);

      const encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
        md: forge.md.sha256.create(),
      });

      return forge.util.encode64(encrypted);
    } catch (error) {
      console.error('Error al cifrar los datos:', error);
      throw error;
    }
  }
}
