import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, Users } from '@core/interfaces/User.interface';
import { UsersService } from '@core/services/configurations/users.service';
import { map, Observable, startWith } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-list-user-profiles',
  templateUrl: './dialog-list-user-profiles.component.html',
  styleUrls: ['./dialog-list-user-profiles.component.scss'],
})
export class DialogListUserProfilesComponent implements OnInit {
  public listUser: User[] = [];
  public listUserByProfile: User[] = [];

  public myControl = new FormControl('');
  public filteredOptions: Observable<User[]>;
  public selectedUser: string = '';
  constructor(
    private _userService: UsersService,

    @Inject(MAT_DIALOG_DATA) public data: any | null,
    public dialogRef: MatDialogRef<DialogListUserProfilesComponent>
  ) {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterUser(value || ''))
    );
  }
  ngOnInit(): void {
    this.filterUserByProfile();
    this.getListUser();
  }

  private _filterUser(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.listUser.filter((user) =>
      user.name?.toLowerCase().includes(filterValue)
    );
  }

  getListUser() {
    this._userService.getInfoUser().subscribe(
      (resp: Users) => {
        this.listUser = resp.data as User[];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  filterUserByProfile() {
    this._userService.filterProfileUser(this.data.id).subscribe(
      (resp: Users) => {
        this.listUserByProfile = resp.data as User[];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  changeActiveUser(id: string) {
    // this._userService.updateStatusUser(id).subscribe(
    //   (resp) => {
    //     this._alertService.showSuccess('Estado actualizado correctamente');
    //     this.getListUserProfile();
    //   },
    //   (err) => {
    //     this._alertService.showError('Error al actualizar el estado');
    //   }
    // );
  }

  selectUser(idUser: string) {
    this.selectedUser = idUser;
  }

  deleteProfileUser(id: string) {
    Swal.fire({
      title: 'Estas Seguro?',
      text: 'Estas seguro de eliminar este usuario del perfil?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: ' Si, eliminar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedUser = id;
        this.registerUserToProfile();
      }
    });
  }

  registerUserToProfile() {
    this._userService
      .registerProfileUser(this.selectedUser, this.data.id)
      .subscribe(
        (res: any) => {
          if (res.ok) {
            this.filterUserByProfile();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  changeStatusUser(id: string) {
    this._userService.changeStatusUser(id).subscribe(
      (resp) => {
        this.filterUserByProfile();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  close() {
    this.dialogRef.close(true);
  }
}
