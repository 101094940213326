<div class="iphone-container">
  <div
    class="iphone-x"
    [style]="phoneHeight"
    [ngStyle]="{ width: phoneWidth ? '330px' : 'none' }"
  >
    <div class="header">
      <div class="status-bar">
        <div class="left-icons">
          <span>9:43</span>
          <span class="moon-icon ri-moon-fill"></span>
        </div>
        <div class="right-icons">
          <span class="ri-wifi-fill"></span>
          <span class="battery-icon ri-battery-line"></span>
        </div>
      </div>
      <div class="chat-header">
        <span class="back-button ri-arrow-left-s-line"></span>
        <span class="chat-info">
          <img
            src="/assets/img/ConHector/Personaje_acciones/ConHector_Feliz.png"
            alt="ch-logo"
            class="chat-logo"
          />
          <span class="chat-title">Con-Héctor</span>
          <span class="verification-icon">
            <svg role="img" viewBox="0 0 22 22">
              <path
                d="M20.396 11a3.487 3.487 0 0 0-2.008-3.062 3.474 3.474 0 0 0-.742-3.584 3.474 3.474 0 0 0-3.584-.742A3.468 3.468 0 0 0 11 1.604a3.463 3.463 0 0 0-3.053 2.008 3.472 3.472 0 0 0-1.902-.14c-.635.13-1.22.436-1.69.882a3.461 3.461 0 0 0-.734 3.584A3.49 3.49 0 0 0 1.604 11a3.496 3.496 0 0 0 2.017 3.062 3.471 3.471 0 0 0 .733 3.584 3.49 3.49 0 0 0 3.584.742A3.487 3.487 0 0 0 11 20.396a3.476 3.476 0 0 0 3.062-2.007 3.335 3.335 0 0 0 4.326-4.327A3.487 3.487 0 0 0 20.396 11zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"
              />
            </svg>
          </span>
        </span>
        <span class="menu-button">⋮</span>
      </div>
    </div>

    <div class="whatsapp">
      <div class="chat-date">
        <span>Mon, 29 Jul</span>
      </div>
      <div class="meta-message">
        This business uses a secure service from Meta to manage this chat. Tap
        to learn more.
      </div>

      <!-- Muestra el mensaje inicial enviado -->
      <div class="message sent">
        <span class="message-text">{{ sentMessage }}</span>
        <span class="message-time">8:52 PM</span>
      </div>

      <ng-container *ngFor="let question of questions">
        <!-- Mensaje recibido -->
        <div class="message received">
          <span class="message-text">{{ question.question }}</span>
          <span class="message-time">8:53 PM</span>

          <!-- Handle multiple-choice options -->
          <ng-container *ngIf="question.type === 'multiple-choice'">
            <div class="multiple-choice-options">
              <ng-container
                *ngIf="question.options?.length <= 3; else moreOptions"
              >
                <button
                  class="option-button"
                  *ngFor="let option of question.options"
                >
                  <mat-icon class="ri-share-forward-line icon"></mat-icon>
                  {{ option }}
                </button>
              </ng-container>
              <ng-template #moreOptions>
                <button class="option-button">
                  <mat-icon class="ri-menu-line"></mat-icon>
                  Ver opciones
                </button>
              </ng-template>
            </div>
          </ng-container>
        </div>

        <!-- Mensaje enviado para tipo text y multiple-choice -->
        <div
          class="message sent"
          *ngIf="
            question.type === 'text' || question.type === 'multiple-choice'
          "
        >
          <span class="message-text">
            {{ question.type === 'text' ? '...' : question.options[0] }}
          </span>
          <span class="message-time">8:53 PM</span>
        </div>
      </ng-container>
    </div>

    <div class="chat-input">
      <span class="input-icons">+</span>
      <input type="text" class="message-input" placeholder="Type a message" />
      <span class="input-icons ri-camera-line"></span>
      <span class="input-icons ri-mic-line"></span>
    </div>
    <div class="home-button"></div>
  </div>
</div>
