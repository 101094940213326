import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatchService } from '@core/services/programs/match.service';
import { TreeNode } from 'primeng/api';
import Swal from 'sweetalert2';
import { DialogAddLevelComponent } from '../dialog-add-level/dialog-add-level.component';
import { FindNode } from '@core/interfaces/findNode.interface';

@Component({
  selector: 'app-diagram',
  templateUrl: './diagram.component.html',
  styleUrls: ['./diagram.component.css'],
})
export class DiagramComponent implements OnInit, OnChanges {
  data2: TreeNode[] = [];

  @Input() dataLevels: any;
  @Input() match: any;
  @Output() reloadData = new EventEmitter<any>();

  transformedData: TreeNode<any>[] = [];

  selectedNodes!: TreeNode[];
  public nodoAnterior: any;

  constructor(
    public dialog: MatDialog,
    private matchService: MatchService
  ) {}

  ngOnInit() {
    this.updateDiagram();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataLevels'] && !changes['dataLevels'].isFirstChange()) {
      this.updateDiagram();
    }
  }

  updateDiagram() {
    this.transformedData = this.transformBackendData(
      this.dataLevels,
      1,
      true,
      true
    );
  }

  onNodeSelect(event: any) {}

  onNodeContextMenu(event: MouseEvent, nodeData: any, data: any) {
    const dialogRef = this.dialog.open(DialogAddLevelComponent, {
      width: '70vw',
      data: {
        level: nodeData.level,
        position: nodeData.position,
        Match: this.match,
        isUpdate: 'notUpdate',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.reloadInfo(result);
    });
    event.preventDefault();
  }

  transformBackendData(
    levels: any | any[],
    level: number,
    isYellow: boolean,
    isFirst: boolean = false
  ): TreeNode[] {
    if (!Array.isArray(levels)) {
      levels = [levels];
    }

    return levels.map((levelData: any) => {
      const treeNode: TreeNode = {
        expanded: true,
        type: 'person',
        styleClass: isFirst
          ? 'dark-blue-node'
          : this.getNodeStyleClass(isYellow),
        data: {
          name: levelData.program.program_name,
          level: level,
          position: levelData.position,
        },
        children: this.transformBackendData(
          levelData.children || [],
          level + 1,
          !isYellow
        ),
      };
      isFirst = false;
      return treeNode;
    });
  }

  getNodeStyleClass(isYellow: boolean): string {
    return isYellow ? 'yellow-node' : 'light-blue-node';
  }

  deleteOrUpdate(nodeData: any, data: any) {
    this.searchNodeParentsByLevelPosition(
      nodeData.position,
      nodeData.level,
      this.match.levels
    );

    Swal.fire({
      title: '¿Qué acción deseas realizar?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      denyButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#262D48',
      denyButtonColor: '#FF6D39',
      cancelButtonColor: '#6c757d',
      width: '30rem',
    }).then((result) => {
      if (result.isConfirmed) {
        const dialogRef = this.dialog.open(DialogAddLevelComponent, {
          width: '70vw',
          data: {
            level: nodeData.level,
            position: nodeData.position,
            Match: this.match,
            isUpdate: 'update',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          this.reloadInfo(result);
        });
      } else if (result.isDenied) {
        this.deleteProgram(
          this.match._id || this.match,
          nodeData,
          this.nodoAnterior
        );
      }
    });
  }

  deleteProgram(matchId: string, nodeData: any, nodoAnterior: any) {
    console.log({ matchId, nodeData, nodoAnterior });
    this.matchService
      .deleteLevel(matchId, nodoAnterior.level, nodoAnterior.position, nodeData)
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'error',
            title: 'Se ha eliminado el programa',
            timer: 3000,
            backdrop: false,
            toast: true,
            position: 'top-end',
          });
          this.reloadInfo({ method: 'delete', response: res.match });
        },
        (err) => {
          console.log(err);
          Swal.fire('Se ha presentado un error', '', 'error');
        }
      );
  }

  searchNodeParentsByLevelPosition(
    position: string,
    level: Number,
    levelslist: any
  ) {
    let data = {
      targetPosition: position,
      targetLevel: level,
      levels: levelslist,
    };

    this.matchService.searchNodeParents(data).subscribe(
      (res: FindNode) => {
        if (res.ok) {
          this.nodoAnterior = res.node;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  deleteFirstLevelComplete(node: TreeNode<any>) {
    this.matchService
      .deleteFirstLevel(this.match.id, node.data.position)
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            title: 'Se ha eliminado el programa',
            timer: 3000,
            backdrop: false,
            toast: true,
            position: 'top-end',
          });
          this.reloadInfo();
        },
        (err) => {
          console.log(err);
          Swal.fire('Se ha presentado un error', '', 'error');
        }
      );
  }

  reloadInfo(data?: any) {
    this.reloadData.emit(data);
  }
}
