<header
  class="header-area bg-white border-bottom-radius box-shadow mb-15"
  [ngClass]="{ active: isToggled, sticky: isSticky }"
  [class.component-dark-theme]="themeService.isDark()"
  [class.right-sidebar]="themeService.isRightSidebar()"
  [class.hide-sidebar]="themeService.isHideSidebar()"
  [class.dark-header]="themeService.isHeaderDark()"
  [class.card-borderd-theme]="themeService.isCardBorder()"
  [class.card-border-radius]="themeService.isCardBorderRadius()"
  [class.rtl-enabled]="themeService.isRTLEnabled()"
>
  <div class="d-flex align-items-center justify-content-space-between">
    <div class="header-left-side d-flex align-items-center">
      <div
        class="burger-menu"
        (click)="toggle()"
        [ngClass]="{ active: isToggled }"
      >
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
      </div>
      <div class="dropdown">
        <input type="checkbox" id="dropdown" />

        <label class="dropdown__face" for="dropdown">
          <div class="dropdown__text">
            <img
              src="{{ projectSelect.logo }}"
              alt="{{ projectSelect.name }}"
            />
            <span>{{ projectSelect.name }}</span>
          </div>
          <div class="dropdown__arrow"></div>
        </label>

        <ul class="dropdown__items">
          <li *ngFor="let project of listProjects">
            <button
              type="button"
              class="dropdown__item"
              (click)="updateProject(project); closeDropdown()"
            >
              <img src="{{ project.logo }}" alt="{{ project.name }}" />
              <span>{{ project.name }}</span>
            </button>
          </li>
        </ul>
      </div>

      <svg style="display: none">
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="
              1 0 0 0 0
              0 1 0 0 0
              0 0 1 0 0
              0 0 0 18 -7"
            result="goo"
          />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </svg>
    </div>
    <div class="header-center">
      <div class="header-image">
        <img src="assets/img/ConHector/Conhector-horizontal.png" alt="Con" />
      </div>
    </div>
    <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
      <li class="d-none d-lg-block">
        <div class="date-btn title-color fw-semibold position-relative">
          <i class="flaticon-calendar"></i>
          {{ formattedDate }}
        </div>
      </li>
    </ul>
  </div>
</header>
