<div
  class="breadcrumb-card mb-15 d-md-flex align-items-center justify-content-space-between"
>
  <ol class="breadcrumb mb-0 ps-0">
    <li
      class="breadcrumb-item"
      *ngIf="breadcrumbs.length && breadcrumbs[0].icon && breadcrumbs[0].label"
    >
      <img
        [src]="breadcrumbs[0].icon"
        alt="Icon"
        class="breadcrumb-icon"
        style="width: 67px; height: 51px; margin-right: 8px"
      />
      <a *ngIf="breadcrumbs[0].url" [routerLink]="breadcrumbs[0].url">
        {{ breadcrumbs[0].label }}
      </a>
      <span *ngIf="!breadcrumbs[0].url">{{ breadcrumbs[0].label || '' }}</span>
    </li>

    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <li class="breadcrumb-item" *ngIf="i !== 0">
        <a *ngIf="breadcrumb.url" [routerLink]="breadcrumb.url">
          {{ breadcrumb.label }}
        </a>
        <span *ngIf="!breadcrumb.url">{{ breadcrumb.label }}</span>
      </li>
    </ng-container>
  </ol>
</div>
