import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Participant } from '@core/interfaces/conversations.interface';
import { CustomizerSettingsService } from '@core/services/customizer-settings.service';

@Component({
  selector: 'app-chat-sidebar',
  templateUrl: './chat-sidebar.component.html',
  styleUrls: ['./chat-sidebar.component.scss'],
})
export class ChatSidebarComponent implements OnChanges {
  public message: string = 'Mensajes';

  @Input() public listParticipant: Participant[] = [];
  @Output() public selectParticipantEvent = new EventEmitter<Participant>();

  constructor(public themeService: CustomizerSettingsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listParticipant']) {
      this.listParticipant = changes['listParticipant'].currentValue;
    }
  }

  selectParticipant(participant: Participant) {
    this.selectParticipantEvent.emit(participant);
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
}
