import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { Line, Lines } from '@core/interfaces/lines.interface';
import { environment } from 'src/environments/environment';
import { Group, Groups } from '@core/interfaces/group.interface';
import { AuthStateService } from '@core/states/auth-state.service';

@Injectable({
  providedIn: 'root',
})
export class LinesGroupsService {
  public urlBackEnd: String = environment.base_url;
  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';

  public urlLines = 'lines';
  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }

  // Método para generar encabezados dinámicamente
  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('user', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getListLines(limit = 120, offset = 50): Observable<Lines> {
    const headers = this.getHeaders();

    return this.http
      .get<Lines>(`${this.urlBackEnd}/${this.urlLines}`, { headers: headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            console.error('Error del cliente:', error.error.message);
          } else {
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage;
          }
          return throwError(error);
        })
      );
  }

  /**
   * Obtiene una lista paginada de lineas.
   * @param {number} page - Número de página.
   * @param {number} limit - Límite de lineas por página.
   * @returns {Observable<Programs>} - Observable que emite un objeto con la lista paginada de lineas.
   */

  getListLinesPaginate(page: number, limit: number): Observable<Lines> {
    //Configurar paramentros de consulta
    const headers = this.getHeaders();

    const params = new HttpParams()
      .set('page', page.toString())
      .set('limit', limit.toString());
    return this.http
      .post<Lines>(
        `${this.urlBackEnd}/${this.urlLines}/paginate`,
        {},
        { params: params, headers: headers }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de las lineas';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  registerLine(data: Line, listGroup: Group[]): Observable<Lines> {
    const headers = this.getHeaders();
    let lineGroup = {
      lineData: data,
      listGroup,
    };

    return this.http
      .post<Lines>(`${this.urlBackEnd}/${this.urlLines}`, lineGroup, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }

  getLinesById(id: string): Observable<Lines> {
    const headers = this.getHeaders();
    return this.http
      .get<Lines>(`${this.urlBackEnd}/${this.urlLines}/${id}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las lineas *getLinesById*';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getLinesById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  deletLineById(id: string, line: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .delete(`${this.urlBackEnd}/${this.urlLines}/${id}/${line}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de la línea ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de deleteLineById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  updateLineById(data: any, id: string, listGroup: any): Observable<any> {
    const headers = this.getHeaders();
    let lineGroup = {
      ...data,
      groups: listGroup,
    };
    return this.http
      .patch(`${this.urlBackEnd}/${this.urlLines}/${id}`, lineGroup, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de la línea ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de updateLineById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  //----------------------------------------------
  //---------------Grupos-------------------------
  //----------------------------------------------

  createGroup(data: Group | Group[]): Observable<Groups> {
    const headers = this.getHeaders();
    return this.http
      .post<Groups>(`${this.urlBackEnd}/groups`, data, { headers: headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los grupos ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de registerGroup: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  filterGroupsForLines(idLine: string): Observable<Groups> {
    const headers = this.getHeaders();
    return this.http
      .get<Groups>(`${this.urlBackEnd}/groups/filterGroups/${idLine}`, {
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los grupos ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de filterGroupsForLines: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  updateGroupById(data: any, id: string): Observable<any> {
    const headers = this.getHeaders();
    return this.http
      .patch(`${this.urlBackEnd}/groups/${id}`, data, { headers: headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los grupos ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de updateGroupById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  deleteGroupById(id: string, group: string, line: string): Observable<any> {
    const headers = this.getHeaders();

    const params = new HttpParams()
      .set('idGroup', id)
      .set('groupMigrate', group)
      .set('lineMigrate', line);

    return this.http
      .delete(`${this.urlBackEnd}/groups`, {
        params: params,
        headers: headers,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = 'Ocurrió un error en la solicitud de los grupos ';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de deleteGroupById: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
