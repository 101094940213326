import { Component } from '@angular/core';
import { CustomizerSettingsService } from 'src/app/core/services/customizer-settings.service';

@Component({
  selector: 'app-customizer-settings',
  templateUrl: './customizer-settings.component.html',
  styleUrls: ['./customizer-settings.component.scss'],
})
export class CustomizerSettingsComponent {
  isToggled = false;

  constructor(public themeService: CustomizerSettingsService) {
    this.themeService.isToggled$.subscribe((isToggled) => {
      this.isToggled = isToggled;
    });
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggle() {
    this.themeService.toggle();
  }
}
