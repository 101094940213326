<mat-card
  class="mb-25 chat-sidebar tagus-card"
  [class.component-dark-theme]="themeService.isDark()"
  [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
  <mat-card-header>
    <h5 class="mb-0">Messages</h5>
  </mat-card-header>
  <mat-card-content>
    <form class="search-box position-relative">
      <input
        type="text"
        class="input-search d-block"
        placeholder="Search here.."
      />
      <button type="submit"><i class="ri-search-line"></i></button>
    </form>
    <ng-scrollbar visibility="hover">
      <div class="sidebar-body">
        <span
          class="sub-title d-block muted-color fw-normal fs-14 position-relative"
        >
          <i class="flaticon-comment"></i>
          ALL MESSAGES
        </span>
        <ul class="sidebar-list ps-0 list-unstyled mb-0">
          <li
            class="position-relative d-flex align-items-center justify-content-space-between"
            *ngFor="let participant of listParticipant"
            style="cursor: pointer"
          >
            <div
              class="content d-flex align-items-center"
              (click)="selectParticipant(participant)"
            >
              <div class="img position-relative">
                <img
                  src="assets/img/user.png"
                  width="45"
                  class="rounded-circle"
                  alt="user"
                />
                <span class="active-status"></span>
              </div>
              <div class="title ms-10">
                <h5 class="fs-15 mb-4">{{ participant.name }}</h5>
              </div>
            </div>
            <div class="info" align="end">
              <span class="time fs-13 muted-color mb-3 d-block">4:30 PM</span>
            </div>
          </li>
        </ul>
      </div>
    </ng-scrollbar>
  </mat-card-content>
</mat-card>
