import { Component } from '@angular/core';

@Component({
  selector: 'app-add-edit-messages',
  templateUrl: './add-edit-messages.component.html',
  styleUrls: ['./add-edit-messages.component.scss'],
})
export class AddEditMessagesComponent {
  public title: string = 'Registrar Mensaje';
}
