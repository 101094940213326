<div class="row">
  <div class="col-lg-4 col-xxl-3">
    <app-fm-sidebar
      [folders]="listFolder"
      (folderSelect)="folderSelect($event)"
    ></app-fm-sidebar>
  </div>
  <div class="col-lg-8 col-xxl-9">
    <div class="row">
      <div class="col-sm-6 col-xxl-3" *ngFor="let file of listFiles">
        <mat-card class="mb-25 tagus-card card-box">
          <mat-card-header class="mb-0">
            <mat-checkbox class="fm-checkbox"></mat-checkbox>
            <button
              type="button"
              mat-button
              class="position-relative dot-btn p-0 bg-transparent border-none"
              [matMenuTriggerFor]="monthMenu"
            >
              <i class="ri-more-fill"></i>
            </button>
            <mat-menu
              #monthMenu="matMenu"
              class="dotMenu monthMenu"
              xPosition="before"
            >
              <button
                mat-menu-item
                type="button"
                class="bg-transparent border-none position-relative"
              >
                <i class="ri-edit-line"></i>
                Rename
              </button>
              <button
                mat-menu-item
                type="button"
                class="bg-transparent border-none position-relative"
              >
                <i class="ri-download-cloud-2-line"></i>
                Download
              </button>
              <button
                mat-menu-item
                type="button"
                class="bg-transparent border-none position-relative"
              >
                <i class="ri-delete-bin-line"></i>
                Remove
              </button>
            </mat-menu>
          </mat-card-header>
          <mat-card-content>
            <div class="pt-50 pb-50 text-center">
              <img src="{{ file.mediaLink }}" alt="folder" width="150px" />
              <span class="d-block fw-semibold mt-10">
                <a routerLink="/file-manager/media">{{ file.name }}</a>
              </span>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
