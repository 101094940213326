import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubCategories } from '@core/interfaces/subCategoriesProgram.interface';
import { AuthStateService } from '@core/states/auth-state.service';
import { catchError, Observable, retry, shareReplay, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubCategoriesProgramsService {
  public urlBackend: string = environment.base_url;
  public tokenUser: string = '';
  public idUser: string = '';
  public project: string = '';
  public urlCategory: string = 'categories-program';

  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService
  ) {
    this.authStateService.token$.subscribe((token) => {
      this.tokenUser = token || '';
    });

    this.authStateService.userId$.subscribe((id) => {
      this.idUser = id || '';
    });

    this.authStateService.project$.subscribe((project) => {
      this.project = project || '';
    });
  }
  // Método para generar encabezados dinámicamente
  private getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenUser}`)
      .set('iduser', `${this.idUser}`)
      .set('project', `${this.project}`);
  }

  getListSubCategory(): Observable<SubCategories> {
    const headers = this.getHeaders();

    return this.http
      .get<SubCategories>(`${this.urlBackend}/${this.urlCategory}`, {
        headers: headers,
      })
      .pipe(
        retry(1),
        shareReplay(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListSubCategory: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }

  postSubCategory(name: string): Observable<SubCategories> {
    const headers = this.getHeaders();
    return this.http
      .post<SubCategories>(
        `${this.urlBackend}/${this.urlCategory}`,
        { name: name },
        {
          headers: headers,
        }
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage =
            'Ocurrió un error en la solicitud de las project list';
          if (error.error instanceof ErrorEvent) {
            // Error del cliente, como red no disponible
            console.error('Error del cliente:', error.error.message);
          } else {
            // El servidor devolvió un código de error
            console.error(
              `Error en la solicitud de getListSubCategory: ${error.status}, ` +
                `Mensaje de error: ${error.error.msg}`
            );
            errorMessage = error.error.msg || errorMessage; // Usar el mensaje de error del servidor si está disponible
          }
          // Devuelve el mensaje de error al componente
          return throwError(error);
        })
      );
  }
}
